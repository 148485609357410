import React from 'react'
import { TbPointFilled } from "react-icons/tb";
import { FaCheck } from "react-icons/fa";

const TpGraphConfig = (props) => {

    const F_ToggleGraph=(funcIdx)=>{
        if (props.fToggleGraph) {
            props.fToggleGraph(props.nGraphId, funcIdx);            
        }else{
            console.log("No fToggleGraph");
        }
    }

    const F_SetGraphValue=(e, funcIdx)=>{
        if (props.fSetGraphValue) {
            props.fSetGraphValue(e, props.nGraphId, funcIdx)
        }else{
            console.log("No fSetGraphValue");
        }
    }
    // props.curConfig
  return (
    <div>
        <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(1)}
                >{props.curConfig?.sel?<FaCheck/>:<div/>}</div>
            <div>{props.graphName}</div>
        </div>
        {props.curConfig?.sel?<div>
            <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(2)}
                >{props.curConfig?.hiSel?<FaCheck/>:<div/>}</div>
            <div>Hi Limit</div>
            {props.curConfig?.hiSel?<input type="number" onChange={(e)=>F_SetGraphValue(e, 1)}
             className="stayRight Summer_info_input" placeholder="<Insert value>"/>:<div/>}
            </div>
            <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(3)}
                >{props.curConfig?.loSel?<FaCheck/>:<div/>}</div>
            <div>Lo Limit</div>
            {props.curConfig?.loSel?<input type="number" onChange={(e)=>F_SetGraphValue(e, 2)} 
            className="Summer_info_input stayRight" placeholder="<Insert value>"/>:<div/>}
            </div>
        </div>:<div/>}
        </div>
  )
}

export default TpGraphConfig
