import http from "../httpService";

async function dataDownloadCsvZip(_id, unix, processState) {
    let url = process.env.REACT_APP_DASERVER_URL+"/damodule/reldownload/getzip";
    let body = { _id, unix, processState };
    return await http.post(url, body, {
        responseType: 'blob',
    });  
}

async function dataDownload_getRdyList(user_id) {  
    let url = process.env.REACT_APP_DASERVER_URL+"/damodule/reldownload/readylist";
    let body = { user_id };
    return await http.post(url, body);  
}


const expModule = {
    dataDownloadCsvZip,
    dataDownload_getRdyList,
};
export default expModule;