import React from "react";
import { PiGraph } from "react-icons/pi";
import { AiOutlineSetting } from "react-icons/ai";
import { useNavigate, Link } from 'react-router-dom';

const MainPage = () => {
  const componentList = [
    "Analysis",
    "Setting",
    "Analysis",
    "Setting",
    "Analysis",
    "Setting",
    "Analysis",
    "Setting",
    "Analysis",
    "Setting",
    "Setting",
  ];

  const hiddenList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  
  const navigate = useNavigate();

  const F_toPage=(pgIdx)=>{
    if (pgIdx===1){
      navigate("/module/main"); 
    }
  }
  return (
    <div className="Summer_EachPg bothCenter">
      <div className="sortHor boxLayout Summer_MainCardContainer_VarWidth">
        <div className="Summer_MainCard Summer_Shadow hoverPointer"
        onClick={()=>F_toPage(1)}>
          <div>
            <div className="Summer_MainCard_Icon"><PiGraph /></div>
            <div className="Summer_MainCard_Text">Analysis</div>
          </div>
        </div>

        <div className="Summer_MainCard Summer_Shadow hoverPointer"
        onClick={()=>F_toPage(2)}>
          <div>
            <div className="Summer_MainCard_Icon"><AiOutlineSetting /></div>
            <div className="Summer_MainCard_Text">Setting</div>
          </div>
        </div>

        
        {/* {componentList.map((c, ind) => (
          <div key={`reqList_${ind}`} className="">
            <div className="Summer_MainCard Summer_Shadow">
              <div>picture</div>
              <div>{c}</div>
            </div>
          </div>
        ))} */}

        {/* {hiddenList.map((c, ind) => (
          <div key={`reqList_${ind}`} className="">
            <div className="Summer_MainCard_hid hid_withSpace">
              <div>hid picture</div>
              <div>{c}</div>
            </div>
          </div>
        ))} */}

        {/* <div className="Summer_MainCard hid_withSpace">
          <div>picture</div>
          <div>empth</div>
        </div> */}
      </div>
    </div>
  );
};

export default MainPage;
