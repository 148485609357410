import React, { useEffect, useState } from "react";
import { LuMaximize, LuMinimize, LuListFilter } from "react-icons/lu";
import { IoSearchSharp, IoClose } from "react-icons/io5";
import { sortTemplate } from "../../../function/Utilities/ArrayFn";
import { FaCheck } from "react-icons/fa";

// import { LuListFilter } from "react-icons/lu";
const TpSelection = (props) => {
  const [G_FilterMode, setG_FilterMode] = useState(1);
  const [G_isFilterMode, setG_isFilterMode] = useState(false);

  const [G_SelList, setG_SelList] = useState([]);
  const [G_SelList_Ori, setG_SelList_Ori] = useState([]);
  const [G_DispalyName, setG_DispalyName] = useState();
  const [G_SelTitle, setG_SelTitle] = useState("");
  const [G_SelMode, setG_SelMode] = useState("");
  const [G_Selected_id, setG_Selected_id] = useState([]);

  // const [G_Test, setG_Test] = useState(true);

  useEffect(() => {
    async function startUp() {
      /** if the input option dont have _id, add it manually */
      F_ConfigureTpSel();
      F_HandleSelItem();
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const F_ConfigureTpSel = () => {
    if (props.selType?.toLowerCase() ==="multi"){
      setG_SelMode("multi")
    }
    

  }


  const F_HandleSelItem = () => {
    /** set title */
    if (props.titleName) setG_SelTitle(props.titleName);
    /** get display name */
    let dispalyName = "";
    if (props.disName) {
      dispalyName = props.disName;
      setG_DispalyName(props.disName);
    }
    /** process display list */
    let tempSelList = [];
    if (props.selList) {
      tempSelList = JSON.parse(JSON.stringify(props.selList));
      if (dispalyName !== "") {
        /** name valid, do sorting */
        let sortKeys = [{ key: dispalyName, descOrder: false }];
        tempSelList.sort((a, b) => sortTemplate(a, b, sortKeys));
        setG_SelList(tempSelList);
        setG_SelList_Ori(tempSelList);
      }
    }
  };

  const F_HandleCancel = () => {
    if (props.onClickCancel) props.onClickCancel();
  };

  const F_ToggleFilter = () => {
    setG_isFilterMode(!G_isFilterMode);
  };


  const F_PickItem=(selItem)=>{
    let _Selected_id = JSON.parse(JSON.stringify(G_Selected_id));
    let fouindIdx = _Selected_id.findIndex(c=>c ===selItem._id);
    if (fouindIdx>=0) { // found, to remove
      _Selected_id.splice(fouindIdx, 1)
    }else {   // not found, to add
      _Selected_id.push(selItem._id)
    }
    setG_Selected_id(_Selected_id)
  }

  const F_SelMatch=(item)=>{
    let foundIdx = G_Selected_id.findIndex(c=>c === item._id)
    return foundIdx >= 0;
  }

  const F_HandleSelect = (selItem) => {
    if (props.onClickSelItem) {
      if (G_SelMode==='multi') {
        F_PickItem(selItem)
      }else{    // single select item
        props.onClickSelItem(selItem);
      }
    }
  };

  const F_ConfirmMultiSelect=()=>{
    if(props.onClickSelItem){
      /** compile the selected list */
      let selList = []
      let filterList = G_SelList_Ori.filter(c=>G_Selected_id.includes(c._id))

      /** pass the selected list to  */
      props.onClickSelItem(filterList);
    }
  }

  const F_SearchItem = (event) => {
    const searchInput = event.target.value;
    let filtered = G_SelList_Ori.filter(
      (c) =>
        c[G_DispalyName].toUpperCase().search(searchInput.toUpperCase()) >= 0
    );
    setG_SelList(filtered);
  };

  return (
    <div className="Summer_DialogPg">
      <div className="Summer_GreyoutPage"></div>
      <div className="Summer_DialogBox Summer_Shadow Summer_HidFrameContainer">
        <div className="Summer_DiaCancel" onClick={F_HandleCancel}>
          <IoClose className="Summer_DiaCancelPos" />
        </div>
        <div className="Summer_DiaTitle">{`${
          G_SelTitle.trim() === "" ? "Selection List" : `${G_SelTitle}`
        }`}</div>

        <div className="Summer_DiaSearchContainer sortHor">
          {G_isFilterMode ? (
            <div className="Summer_DiaFilterTitle">Filter</div>
          ) : (
            <div className="Summer_DiaSearchBox sortHor">
              <input
                className="Summer_input_Frameless"
                type="text"
                onChange={F_SearchItem}
              />
              <div className="Summer_DiaSearchIcon bothCenter">
                <IoSearchSharp />
              </div>
            </div>
          )}

          {G_isFilterMode ? (
            <div
              className={`Summer_SelFilterIcon_Active bothCenter hoverPointer`}
              onClick={F_ToggleFilter}
            >
              <LuListFilter className={`Summer_SelFilterIconPos_Active`} />
            </div>
          ) : (
            <div
              className={`Summer_FilterIcon bothCenter hoverPointer`}
              onClick={F_ToggleFilter}
            >
              <LuListFilter className={``} />
            </div>
          )}
        </div>

        <div
          className={`Summer_DiaItemContainer ${G_SelMode==="multi"?"shorterHeight":""} ${
            G_isFilterMode ? "filterMode" : ""
          }`}
        >
          {G_isFilterMode ? (
            <div>
              <div>Filter Template</div>
            </div>
          ) : (
            <div className="">
              {G_SelList.map((c, ind) => (
                <div
                  key={`setTpKey_${props.titleName}_${ind}`}
                  className="Summer_TpSel_Item hoverPointer sortHor"
                  onClick={() => F_HandleSelect(c)}
                >
                  {G_SelMode==="multi"?<div className="Summer_TpSel_CheckBox bothCenter">{F_SelMatch(c)?<FaCheck/>:<div></div>}</div>:<div></div>}                  
                  <div>{c[G_DispalyName]}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {G_SelMode==="multi"?
          <div className="Summer_TpSelButton hoverPointer" onClick={F_ConfirmMultiSelect}> Confirm</div>:          
          <div></div>
        }
      </div>
    </div>
  );
};

export default TpSelection;
