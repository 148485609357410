import React, { useEffect, useState } from "react";
import { LuMaximize, LuMinimize, LuListFilter } from "react-icons/lu";
import { IoSearchSharp, IoClose } from "react-icons/io5";
import { sortTemplate } from "../../../function/Utilities/ArrayFn";
import { FaCheck } from "react-icons/fa";

import { useSelector } from 'react-redux';
import { isIdInLoadedBds, Rdx_getBdLoadedList, Rdx_getBdLoadedUnix } from "../../../reduxStore/actions/sensor_list";
// import { F_LoadBdToRedux } from "../CommonFn/CommonFn";
import RdxLoadBd from "../CommonFn/RdxLoadBd";
import TpSelection from "./TpSelection";
import { IoMdArrowDropdown } from "react-icons/io";
import { F_ExtractFromObj } from "../../../function/Utilities/ObjectFn";

// import { LuListFilter } from "react-icons/lu";
const TpChooseSensorWithBd = (props) => {
  const [G_FilterMode, setG_FilterMode] = useState(1);
  const [G_isFilterMode, setG_isFilterMode] = useState(false);

  const [G_SelList, setG_SelList] = useState([]);
  const [G_SelList_Ori, setG_SelList_Ori] = useState([]);
  const [G_DispalyName, setG_DispalyName] = useState();
  const [G_SelTitle, setG_SelTitle] = useState("");
  const [G_SelMode, setG_SelMode] = useState("");
  const [G_Selected_id, setG_Selected_id] = useState([]);

  const [G_BdList_Loaded, setG_BdList_Loaded] = useState([]);
  const [G_BdList_Display, setG_BdList_Display] = useState([]);

  const [G_nSelectionPg, setG_nSelectionPg] = useState(0);
  const [G_SelBd_Info, setG_SelBd_Info] = useState({});

  const [G_CurrentBdSensorList, setG_CurrentBdSensorList] = useState([]); 
  const [G_SelSensor_Info, setG_SelSensor_Info] = useState({});

  const [G_CurrentSenParaList, setG_CurrentSenParaList] = useState([]); 
  const [G_SelPara_Info, setG_SelPara_Info] = useState({});
  // const [G_Test, setG_Test] = useState(true);

  // const R_BdLoaded = useSelector(state => isIdInLoadedBds(state, 1));
  // const R_BdLoadedUnix = useSelector(Rdx_getBdLoadedUnix);
  // const R_BdList = useSelector(Rdx_getBdLoadedList);
  

  
  const { F_LoadBdToRedux, F_LoadSensorToRedux, F_rdx_LoadSensorPara} = RdxLoadBd();

  useEffect(() => {
    async function startUp() {
      /** if the input option dont have _id, add it manually */
      F_ConfigureTpSel();
      F_HandleSelItem();

      await F_LoadAndSortBd();
      /** 
       * user choose bd
       * check R_BdLoaded, if not loaded
       * load the sensors in bd.
       * store sensor into redux list
       * store bd into bdLoaded list.
       */
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const F_ConfigureTpSel = () => {
    if (props.selType?.toLowerCase() ==="multi"){
      setG_SelMode("multi")
    }
  }

  const F_LoadAndSortBd=async ()=>{
    let bdList = await F_LoadBdToRedux()
    setG_BdList_Loaded(bdList)

    let _BdList_Display = JSON.parse(JSON.stringify(bdList));
    let sortKeys = [{ key: "name", descOrder: false }];
    _BdList_Display.sort((a, b) => sortTemplate(a, b, sortKeys));
    setG_BdList_Display(_BdList_Display);

  }


  const F_HandleSelItem = () => {
    /** set title */
    if (props.titleName) setG_SelTitle(props.titleName);
    /** get display name */
    let dispalyName = "";
    if (props.disName) {
      dispalyName = props.disName;
      setG_DispalyName(props.disName);
    }
    /** process display list */
    let tempSelList = [];
    if (props.selList) {
      tempSelList = JSON.parse(JSON.stringify(props.selList));
      if (dispalyName !== "") {
        /** name valid, do sorting */
        let sortKeys = [{ key: dispalyName, descOrder: false }];
        tempSelList.sort((a, b) => sortTemplate(a, b, sortKeys));
        setG_SelList(tempSelList);
        setG_SelList_Ori(tempSelList);
      }
    }
  };

  const F_HandleCancel = () => {
    if (props.onClickCancel) props.onClickCancel();
  };


  const F_CallSel =(pgIdx)=>{
    setG_nSelectionPg(pgIdx)
  }

  const F_ChooseBd=async (bf_info)=>{
    if(bf_info._id !== G_SelBd_Info._id){   /** different bd selected */
      /** clear the sensor info, and parameter info.*/
      // setG_CurrentBdSensorList([]) //remove this line. Not necessary to clear it since bottom will rewrite it
      setG_SelSensor_Info({})
      /** clear para */
      setG_CurrentSenParaList([])
      setG_SelPara_Info({})
    }
    setG_SelBd_Info(bf_info)
    let sensorList = await F_LoadSensorToRedux(bf_info._id)
    setG_CurrentBdSensorList(sensorList)
    F_CallSel(0)  /** Close the dialog box */
  }

  const F_ChooseSensor=async(sensorInfo)=>{    
    if(sensorInfo.type !== G_SelSensor_Info.type){   /** different bd selected */
      /** clear para */
      // setG_CurrentSenParaList([])  // not necessary, later will re-write.
      setG_SelPara_Info({})
    }
    setG_SelSensor_Info(sensorInfo)
    let paraList = await F_rdx_LoadSensorPara([sensorInfo.type])
    // console.log("paraList", paraList);
    setG_CurrentSenParaList(paraList)
    F_CallSel(0)   /** Close the dialog box */
  }

  const F_ChoosePara=async(paraInfo)=>{
    setG_SelPara_Info(paraInfo)
    F_CallSel(0)
  }


  
  const F_Comfirm=()=>{
    if (props.onClickSelItem) {
      // let confirmInfo = {
      //   bdInfo:F_ExtractFromObj(G_SelBd_Info, ["_id", "name"]), 
      //   sensorInfo:F_ExtractFromObj(G_SelSensor_Info, ["_id", "name", "type", "devID", "buidling_id"]),
      //   paraInfo:F_ExtractFromObj(G_SelPara_Info, ["_id", "dataName", "dataUnit", "sensorType", "dataType","dataIndex"])
      // }
      let confirmInfo = {
        bdInfo:G_SelBd_Info,
        sensorInfo:G_SelSensor_Info,
        paraInfo:G_SelPara_Info
      }
      props.onClickSelItem(confirmInfo)
    }
    
  }
  

  return (
    <div className="Summer_DialogPg">
      {G_nSelectionPg===1 && <TpSelection 
        titleName={'Building'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_ChooseBd}
        selList={G_BdList_Loaded}
        />}

      {G_nSelectionPg===2 && <TpSelection 
        titleName={'Sensor'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_ChooseSensor}
        selList={G_CurrentBdSensorList}
        />}

      {G_nSelectionPg===3 && <TpSelection 
        titleName={'Parameter'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'dataName'}
        onClickSelItem = {F_ChoosePara}
        selList={G_CurrentSenParaList}
        />}





      <div className="Summer_GreyoutPage"></div>
      <div className="Summer_DialogBox lowerZ_idx Summer_Shadow Summer_HidFrameContainer">
        <div className="Summer_DiaCancel" onClick={F_HandleCancel}>
          <IoClose className="Summer_DiaCancelPos" />
        </div>
        <div className="Summer_DiaTitle">{`${
          G_SelTitle.trim() === "" ? "Sensor Parameter" : `${G_SelTitle}`
        }`}</div>

        <div className={`Summer_DiaItemContainer`}>

          <div className="" style={{marginBottom:"10px", marginTop:"10px"}}>
            <div className="Summer_TpChooseBarTitle">Building</div>
            <div className="sortHor hoverPointer Summer_TpChooseBar" onClick={()=>F_CallSel(1)}>
              <div className="  " >
                {G_SelBd_Info?.name ? 
                  <div className="Summer_ConfigedText wrapTextEllipsis" style={{width:"195px"}}>{G_SelBd_Info?.name}</div>:
                  <div className="bothCenter" >{`<Choose building>`}</div>}
              </div>
              <div className="Summer_ConfigedText stayRight bothCenter"><IoMdArrowDropdown/></div>
            </div>
          </div>

          <div className="" style={{marginBottom:"10px"}}>
            <div className="Summer_TpChooseBarTitle">Sensor</div>
            <div className="sortHor stayRight hoverPointer Summer_TpChooseBar" onClick={()=>F_CallSel(2)}>
              <div className="  " >
                {G_SelSensor_Info?.name ? 
                  <div className="Summer_ConfigedText wrapTextEllipsis" style={{width:"130px"}}>{G_SelSensor_Info?.name}</div>:
                  <div className="bothCenter">{`<Choose sensor>`}</div>}
              </div>
              <div className="Summer_ConfigedText stayRight bothCenter"><IoMdArrowDropdown/></div>
            </div>
          </div>
          
          <div className="" style={{marginBottom:"10px"}}>
            <div className="Summer_TpChooseBarTitle">Parameter</div>
            <div className="sortHor stayRight hoverPointer Summer_TpChooseBar" onClick={()=>F_CallSel(3)}>
              <div className="  " >
                {G_SelPara_Info?.dataName ? 
                  <div className="Summer_ConfigedText wrapTextEllipsis" style={{width:"130px"}}>{G_SelPara_Info?.dataName}</div>:
                  <div className="bothCenter">{`<Choose parameter>`}</div>}
              </div>
              <div className="Summer_ConfigedText stayRight bothCenter"><IoMdArrowDropdown/></div>
            </div>
          </div>

          <div className="Summer_DaReq_SendButton hoverPointer" style={{marginTop:"65px"}}
            onClick={F_Comfirm}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default TpChooseSensorWithBd;
