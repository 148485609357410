import React from 'react';
import downloadFn from '../../../function/Download/downloadFn';
import { toast } from 'react-toastify';

function DownloadPg() {
    const handleDownload = async (_id, unix, processState) => {
        try {
            // console.log(ticketInfo);
            // let bdInfo  = localStorageFn.getBdToken();
            // eslint-disable-next-line
            // let zipFileName = ticketInfo.description? ticketInfo.description.replace(/[\\/:\*\?"<>|]/g, '_') : timeFn.convertUnixToFormattedDateTime_UTC8(ticketInfo.unix);
            let zipFileName = "example1";
            let response = await downloadFn.dataDownloadCsvZip(_id, unix, processState);
            // Create a Blob object from the ZIP data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Create a link element and trigger a click event to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = zipFileName;
            link.click();

            // await refreshPg();
        } catch (error) {
            toast.error("Download Error");
            console.log('Error downloading multiple CSV files:', error);
        }
    }

    const getReadyList=async (user_id)=>{
        let reayList = await downloadFn.dataDownload_getRdyList(user_id)
        console.log("reayList", reayList);

    }

    return ( <div>
        <div className='frame-wrapper'>
            <div onClick={()=>handleDownload(1, 1714212342, 1)}>Download</div>
            <div onClick={()=>getReadyList(1)}>Get List</div>
        </div>
    </div> );
}

export default DownloadPg;