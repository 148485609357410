import React, { useEffect, useState } from 'react'
import { IoArrowUndoSharp } from "react-icons/io5";

const TpPgHeader = (props) => {
  const [G_Header, setG_Header] = useState("");

  useEffect(() => {
    async function startUp() {
      if (props.headerTitle){
        setG_Header(props.headerTitle)
      }
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const handleBack=()=>{
    if(props.onClick_Back) props.onClick_Back()
  }

  return (
    <div>
      <div className='sortHor verCenter Summer_TpHeaderBar'>
        <div className=' bothCenter Summer_HeaderBack hoverPointer'
        onClick={handleBack}><IoArrowUndoSharp/></div>
        <div className='bothCenter Summer_HeaderTitle'>{G_Header}</div>
      </div>
    </div>
  )
}

export default TpPgHeader
