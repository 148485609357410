import React, { useEffect, useState } from "react";
import daReqFn from "../../../function/DaServer/DataAnalysisQuery/DaReq_Fn";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import TpPgHeader from "../Common/TpPgHeader";
import { sortTemplate } from "../../../function/Utilities/ArrayFn";

const DaResultDownloadPg = () => {
  const user_id = 1;

  const navigate = useNavigate();

  const [G_ReqList, setG_ReqList] = useState([]);

  useEffect(() => {
    async function startUp() {
      let reqList = await daReqFn.F_GetReqList(user_id);
      // sort item
      let sortKeys = [{ key: "unix", descOrder: true }];
      reqList.sort((a, b) => sortTemplate(a, b, sortKeys));

      setG_ReqList(reqList);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const F_ReqIsReady = (nState) => {
    return nState === 2;
  };

  
async function _F_DownlaodDaZip(_id, unix, processState) {
  let url = process.env.REACT_APP_DASERVER_URL + "/damodule/reldownload/getzip";
  let body = { _id, unix, processState };
  return await axios.post(url, body, {
    responseType: "blob",
  });
}

  const F_HandleDownloadZip = async (reqInfo) => {
    let { _id, unix, processState } = reqInfo;
    // Call the function to get the response
    let response = await _F_DownlaodDaZip(_id, unix, processState);

    // Log all headers to inspect them

    // Extract filename from Content-Disposition header in a case-insensitive manner
    const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'] || response.headers['Content-disposition'];

    let filename = "default_filename.zip";  // Fallback filename in case the header is missing or incorrect

    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    // Create and download the file
    const blob = new Blob([response.data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = decodeURIComponent(filename); // Ensure the filename is correctly decoded
    document.body.appendChild(link);  // Append link to body to ensure it works in all browsers
    link.click();
    document.body.removeChild(link);
  };

  const F_ToPage=(sPage)=>{
    if (sPage==="back") {
      navigate("/module/main"); 
    }
  }

  return (
    <div className="Summer_EachPg">
      <TpPgHeader headerTitle={"Result"} onClick_Back={()=>F_ToPage("back")}/>
      <div className="Summer_PageLayout">
        <div className="Summer_ResultListCard">
          {G_ReqList.map((c, ind) => (
            <div key={`reqList_${ind}`}>
              {ind > 0 && <div className="divSeperateLine"></div>}
              <div  className="sortHor hoverBgc" style={{padding:"5px 0px"}}>
                <div style={{width:"40px"}}>{`${ind+1}.`}</div>
                <div>{c.reqName}</div>
                {F_ReqIsReady(c.processState) ? (
                  <div
                    className="stayRight hoverPointer Summer_RdyToClick"
                    onClick={() => F_HandleDownloadZip(c)}
                  >
                    Download
                  </div>
                ) : (
                  <div className="stayRight">Preparing...</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DaResultDownloadPg;
