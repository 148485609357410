import downlaodSvc from '../../service/Download/downloadSvc';

async function dataDownloadCsvZip(_id, unix, processState) {
    try {
        let rel = await downlaodSvc.dataDownloadCsvZip(_id, unix, processState);        
        return rel;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function dataDownload_getRdyList(user_id) {
    try {
        let rel = await downlaodSvc.dataDownload_getRdyList(user_id);        
        return rel;
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}


const expModule = {
    dataDownloadCsvZip,
    dataDownload_getRdyList,
};
export default expModule;