export const F_HandleHttpResult = (ErrMessage, rel) => {
  if (rel.statusText === "OK") return rel.data;
  else {
    let errMsg = ErrMessage;
    if (rel?.data?.errMsg) {
      errMsg = rel.data.errMsg;
    }
    return { errMsg, rel };
  }
};
