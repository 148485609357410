import React from 'react'
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const TpToggleButton = (props) => {
 const F_Onlick=()=>{
    if(props.onClick_in) props.onClick_in()
 }

  return (
    <div className='bothCenter' onClick={F_Onlick}>
      {props?.status?<BsToggleOn className='Summer_GreenText'/>:<BsToggleOff />}
    </div>
  )
}

export default TpToggleButton
