import React, { useState } from "react";
import TpPgHeader from "../../../Common/TpPgHeader";
import { useNavigate } from "react-router-dom";
import TpToggleButton from "../../../Common/TpToggleButton";
import { FaCheck } from "react-icons/fa";
import { TbPointFilled } from "react-icons/tb";
import TpTimeRangeAndValue from "../../../Common/TpTimeRangeAndValue";
import { toast } from "react-toastify";
import TpSelection from "../../../Common/TpSelection";
import TpChooseSensorWithBd from "../../../Common/TpChooseSensorWithBd";
import { F_ExtractFromObj } from "../../../../../function/Utilities/ObjectFn";
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { F_Arr_ShiftDel, F_Arr_ShiftDn, F_Arr_ShiftUp, isArrayWithContent } from "../../../../../function/Utilities/ArrayFn";
import daReqFn_R02 from "../../../../../function/DaServer/DataAnalysisQuery/DaReq_Fn";
import { F_RotatePivotInterval, F_RotateStatisticalMeasure, F_AllTimeZone } from "../CommonFn/ModuleCommonFn";
import { F_GetUserId } from "../../../../../function/UserManagement/UserFn";

const Module0002DialyOverlap = () => {
  const G_ModuleID = 2;
  
  const GC_Init_xCL={selected: false, startTime:"", endTime:"", value:null}

  const [G_GroupByWeek, setG_GroupByWeek] = useState(true);
  const [G_ModuleCfg, setG_ModuleCfg] = useState({HighLight_List:[GC_Init_xCL]});

  const [G_sModuleName, setG_sModuleName] = useState("");
  const [G_sStartDate, setG_sStartDate] = useState("");
  const [G_sEndDate, setG_sEndDate] = useState("");
  const [G_nSelectionPg, setG_nSelectionPg] = useState(0);

  const [G_GraphParaList, setG_GraphParaList] = useState([]);

  const [G_CompareParaList, setG_CompareParaList] = useState([]);

  const [G_sPivotInterval, setG_sPivotInterval] = useState("10min");
  const [G_sStatMeasure, setG_sStatMeasure] = useState("mean");
  const [G_bMultiLanguage, setG_bMultiLanguage] = useState(false);

  const [G_sTimezone, setG_sTimezone] = useState("");

  const navigate = useNavigate();

  const F_ToPage = (pgIdx) => {
    if (pgIdx === 0) {
      navigate("/module/main");
    }
  };

  const F_SetName_Date=(event, fn)=>{
    const sVal = event.target.value
    if(fn===1)  setG_sModuleName(sVal)
    else if (fn===2)  setG_sStartDate(sVal)
    else if (fn===3)  setG_sEndDate(sVal)
  }
  
  const F_Toggle = (Action) => {
    if (Action === "GroupByWeek") {
      setG_GroupByWeek(!G_GroupByWeek);
    }else if (Action === "MultiLanguage"){
      setG_bMultiLanguage(!G_bMultiLanguage);
    }else if (Action==="Toggle_CL"){
      let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
      _ModuleCfg.bCL = !_ModuleCfg?.bCL
      
      /** set CL_ListAlso */
      if(_ModuleCfg.bCL===true)  _ModuleCfg.CL_List = [GC_Init_xCL]
      else {
        _ModuleCfg.CL_List = []
        delete _ModuleCfg.CL_Value;
      }

      setG_ModuleCfg(_ModuleCfg)
    }else if (Action==="Toggle_UCL"){
      let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
      _ModuleCfg.bUCL = !_ModuleCfg?.bUCL
      /** set UCL_ListAlso */
      if(_ModuleCfg.bUCL===true)  _ModuleCfg.UCL_List = [GC_Init_xCL]
      else {
        _ModuleCfg.UCL_List = []        
        delete _ModuleCfg.UCL_Value;
      }
      setG_ModuleCfg(_ModuleCfg)
    }else if (Action==="Toggle_LCL"){
      let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
      _ModuleCfg.bLCL = !_ModuleCfg?.bLCL
      /** set LCL_ListAlso */
      if(_ModuleCfg.bLCL===true)  _ModuleCfg.LCL_List = [GC_Init_xCL]
      else {
        _ModuleCfg.LCL_List = []        
        delete _ModuleCfg.LCL_Value;
      }
      setG_ModuleCfg(_ModuleCfg)
    }else if (Action==="Y_HiLimit"){
      let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
      _ModuleCfg.bY_Hi = !_ModuleCfg?.bY_Hi      
      if (!_ModuleCfg.bY_Hi){
        delete _ModuleCfg.Y_HiLimit_Value;
      }
      setG_ModuleCfg(_ModuleCfg)
    }else if (Action==="Y_LoLimit"){
      let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
      _ModuleCfg.bY_Lo = !_ModuleCfg?.bY_Lo      
      if (!_ModuleCfg.bY_Lo){
        delete _ModuleCfg.Y_LoLimit_Value;
      }
      setG_ModuleCfg(_ModuleCfg)
    }
  };
  const F_SetValue=(event, xCL)=>{
    const sValue = event.target.value;
    let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));

    if (xCL==="UCL"){
      _ModuleCfg.UCL_Value = parseFloat(sValue)
    }else if (xCL==="LCL"){
      _ModuleCfg.LCL_Value = parseFloat(sValue)
    }else if (xCL==="CL"){
      _ModuleCfg.CL_Value = parseFloat(sValue)
    }else if (xCL==="Y_HiLimit"){
      _ModuleCfg.Y_HiLimit_Value = parseFloat(sValue)
    }else if (xCL==="Y_LoLimit"){
      _ModuleCfg.Y_LoLimit_Value = parseFloat(sValue)
    }

    setG_ModuleCfg(_ModuleCfg)
  }

  const F_ToggleCuz_xCL = (xCL, index) => {
    let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
    if (xCL==="UCL"){
      _ModuleCfg.UCL_List[index].selected = !_ModuleCfg.UCL_List[index].selected
      /** add more  */
      if(_ModuleCfg.UCL_List[index].selected){    /** selected, add 1 more line */
        _ModuleCfg.UCL_List.push(GC_Init_xCL)
      }else{  /** deselect, delete 1 line */
        _ModuleCfg.UCL_List.splice(index,1)
      }
    } else if (xCL==="CL"){
      _ModuleCfg.CL_List[index].selected = !_ModuleCfg.CL_List[index].selected
      /** add more  */
      if(_ModuleCfg.CL_List[index].selected){    /** selected, add 1 more line */
        _ModuleCfg.CL_List.push(GC_Init_xCL)
      }else{  /** deselect, delete 1 line */
        _ModuleCfg.CL_List.splice(index,1)
      }
    } else if (xCL==="LCL"){
      _ModuleCfg.LCL_List[index].selected = !_ModuleCfg.LCL_List[index].selected
      /** add more  */
      if(_ModuleCfg.LCL_List[index].selected){    /** selected, add 1 more line */
        _ModuleCfg.LCL_List.push(GC_Init_xCL)
      }else{  /** deselect, delete 1 line */
        _ModuleCfg.LCL_List.splice(index,1)
      }
    } else if (xCL==="HighLight"){
      _ModuleCfg.HighLight_List[index].selected = !_ModuleCfg.HighLight_List[index].selected
      /** add more  */
      if(_ModuleCfg.HighLight_List[index].selected){    /** selected, add 1 more line */
        _ModuleCfg.HighLight_List.push(GC_Init_xCL)
      }else{  /** deselect, delete 1 line */
        _ModuleCfg.HighLight_List.splice(index,1)
      }

    }

    setG_ModuleCfg(_ModuleCfg)
  }
  
  
  const F_SetStart=(event, idx, paraType, xCL)=>{    
    const sValue = event.target.value;
    let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));

    if(paraType==="Start"){
      if (xCL==="CL") _ModuleCfg.CL_List[idx].startTime = sValue;
      else if (xCL==="UCL") _ModuleCfg.UCL_List[idx].startTime = sValue;
      else if (xCL==="LCL") _ModuleCfg.LCL_List[idx].startTime = sValue;
      else if (xCL==="HighLight") _ModuleCfg.HighLight_List[idx].startTime = sValue;
    }else if (paraType==="End"){
      if (xCL==="CL") _ModuleCfg.CL_List[idx].endTime = sValue;
      else if (xCL==="UCL") _ModuleCfg.UCL_List[idx].endTime = sValue;
      else if (xCL==="LCL") _ModuleCfg.LCL_List[idx].endTime = sValue;
      else if (xCL==="HighLight") _ModuleCfg.HighLight_List[idx].endTime = sValue;
    }else if (paraType==="value"){
      if (xCL==="CL") _ModuleCfg.CL_List[idx].value = parseFloat(sValue);
      if (xCL==="UCL") _ModuleCfg.UCL_List[idx].value = parseFloat(sValue);
      if (xCL==="LCL") _ModuleCfg.LCL_List[idx].value = parseFloat(sValue);
      // if (xCL==="HighLight") _ModuleCfg.HighLight_List[idx].value = parseFloat(sValue);
    }

    setG_ModuleCfg(_ModuleCfg)
  }

  const F_ValidateConfig=()=>{
    let _inputName = document.getElementById("in_do_name").value;
    let _inputStart = document.getElementById("in_do_start").value;
    let _inputEnd = document.getElementById("in_do_end").value;
    
    if(!_inputName) {
      toast("Error: Name cannot be empty")
      return false;
    }
    if(!_inputStart) {
      toast("Error: Start Date cannot be empty")
      return false;
    }
    if(!_inputEnd) {
      toast("Error: End Date cannot be empty")
      return false;
    }
    if(_inputStart > _inputEnd) {
      toast("Error: Start Date cannot be greater than End Date")
      return false;
    }

    if(G_ModuleCfg.bCL) {
      let _inputCL = document.getElementById("in_do_cl").value;
      if(!_inputCL) {
        toast("Error: CL Value cannot be empty")
        return false;
      }
      for(let i = 0; i < G_ModuleCfg.CL_List.length; i++) {
        if(G_ModuleCfg.CL_List[i].selected) {
          let _inputCLStart = document.getElementById(`CL_${i}_cus_start`).value;
          let _inputCLEnd = document.getElementById(`CL_${i}_cus_end`).value;
          let _inputCLValue = document.getElementById(`CL_${i}_cus_value`).value;
          if(!_inputCLStart) {
            toast(`Error: CL ${i+1} Start Time cannot be empty`)
            return false;
          }
          if(!_inputCLEnd) {
            toast(`Error: CL ${i+1} End Time cannot be empty`)
            return false;
          }
          if(_inputCLStart > _inputCLEnd) {
            toast(`Error: CL ${i+1} Start Time cannot be greater than End Time`)
            return false;
          }
          if(!_inputCLValue) {
            toast(`Error: CL ${i+1} Value cannot be empty`)
            return false;
          }
        }
      }
    }

    if(G_ModuleCfg.bUCL) {
      let _inputUCL = document.getElementById("in_do_ucl").value;
      if(!_inputUCL) {
        toast("Error: UCL Value cannot be empty")
        return false;
      }
      for(let i = 0; i < G_ModuleCfg.UCL_List.length; i++) {
        if(G_ModuleCfg.UCL_List[i].selected) {
          let _inputUCLStart = document.getElementById(`UCL_${i}_cus_start`).value;
          let _inputUCLEnd = document.getElementById(`UCL_${i}_cus_end`).value;
          let _inputUCLValue = document.getElementById(`UCL_${i}_cus_value`).value;
          if(!_inputUCLStart) {
            toast(`Error: UCL ${i+1} Start Time cannot be empty`)
            return false;
          }
          if(!_inputUCLEnd) {
            toast(`Error: UCL ${i+1} End Time cannot be empty`)
            return false;
          }
          if(_inputUCLStart > _inputUCLEnd) {
            toast(`Error: UCL ${i+1} Start Time cannot be greater than End Time`)
            return false;
          }
          if(!_inputUCLValue) {
            toast(`Error: UCL ${i+1} Value cannot be empty`)
            return false;
          }
        }
      }
    }

    if(G_ModuleCfg.bLCL) {
      let _inputLCL = document.getElementById("in_do_lcl").value;
      if(!_inputLCL) {
        toast("Error: LCL Value cannot be empty")
        return false;
      }
      for(let i = 0; i < G_ModuleCfg.LCL_List.length; i++) {
        if(G_ModuleCfg.LCL_List[i].selected) {
          let _inputLCLStart = document.getElementById(`LCL_${i}_cus_start`).value;
          let _inputLCLEnd = document.getElementById(`LCL_${i}_cus_end`).value;
          let _inputLCLValue = document.getElementById(`LCL_${i}_cus_value`).value;
          if(!_inputLCLStart) {
            toast(`Error: LCL ${i+1} Start Time cannot be empty`)
            return false;
          }
          if(!_inputLCLEnd) {
            toast(`Error: LCL ${i+1} End Time cannot be empty`)
            return false;
          }
          if(_inputLCLStart > _inputLCLEnd) {
            toast(`Error: LCL ${i+1} Start Time cannot be greater than End Time`)
            return false;
          }
          if(!_inputLCLValue) {
            toast(`Error: LCL ${i+1} Value cannot be empty`)
            return false;
          }
        }
      }
    }

    for(let i = 0; i < G_ModuleCfg.HighLight_List.length; i++) {
      if(G_ModuleCfg.HighLight_List[i].selected) {
        let _inputHighLightStart = document.getElementById(`HighLight_${i}_cus_start`).value;
        let _inputHighLightEnd = document.getElementById(`HighLight_${i}_cus_end`).value;
        // let _inputHighLightValue = document.getElementById(`HighLight_${i}_cus_value`).value;
        if(!_inputHighLightStart) {
          toast(`Error: Highlight ${i+1} Start Time cannot be empty`);
          return false;
        }
        if(!_inputHighLightEnd) {
          toast(`Error: Highlight ${i+1} End Time cannot be empty`);
          return false;
        }
        if(_inputHighLightStart > _inputHighLightEnd) {
          toast(`Error: Highlight ${i+1} Start Time cannot be greater than End Time`)
          return false;
        }
        // if(!_inputHighLightValue) {
        //   toast(`Error: Highlight ${i+1} Value cannot be empty`);
        //   return false;
        // }
      }
    }

    return true;
  }

  const F_SendToAnalysis=async()=>{

    F_AllTimeZone()
    
    if (!F_ValidateConfig()) return 

    let comparePara = {}
    if (isArrayWithContent(G_CompareParaList)){
      comparePara=G_CompareParaList[0]
    }

    let moduleInfo = {
      name:G_sModuleName,
      user_id: F_GetUserId(),
      startDate: G_sStartDate,
      endDate:G_sEndDate,
      timezone:G_sTimezone,
      bGroupByWeek:G_GroupByWeek,
      AuxConfig:G_ModuleCfg,
      graphParas: G_GraphParaList,
      comparePara,
      PivotInterval:G_sPivotInterval,
      StatisticMeasure:G_sStatMeasure,
      bSupportChinese:G_bMultiLanguage,
    }
    
    let rel = await daReqFn_R02.F_InsertDaReq_R02(G_ModuleID, moduleInfo);
        
    if (rel.errMsg) {
      return toast(rel.errMsg)
    }
    
    /** success */
    /** show success message */
    toast("Success")
    F_ToPage(0)
  }

  const F_SetDiaPage=(nPg)=>{
    setG_nSelectionPg(nPg)
  }
  

  const F_AddPara=(paraInfo)=>{
    let paraInfo_Extract = {
      bdInfo:F_ExtractFromObj(paraInfo?.bdInfo, ["_id", "name"]), 
      sensorInfo:F_ExtractFromObj(paraInfo?.sensorInfo, ["_id", "name", "type", "devID", "buidling_id"]),
      paraInfo:F_ExtractFromObj(paraInfo?.paraInfo, ["_id", "dataName", "dataUnit", "sensorType", "dataType","dataIndex"])
    }
    
    let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
    _GraphParaList.push(paraInfo_Extract)
    setG_GraphParaList(_GraphParaList)

    F_SetDiaPage(0);  /** Close the selection dialog */
  }

  const F_AddComparePara=(paraInfo)=>{
    let paraInfo_Extract = {
      bdInfo:F_ExtractFromObj(paraInfo?.bdInfo, ["_id", "name"]), 
      sensorInfo:F_ExtractFromObj(paraInfo?.sensorInfo, ["_id", "name", "type", "devID", "buidling_id"]),
      paraInfo:F_ExtractFromObj(paraInfo?.paraInfo, ["_id", "dataName", "dataUnit", "sensorType", "dataType","dataIndex"])
    }
    
    let _CompareParaList = JSON.parse(JSON.stringify(G_CompareParaList))
    _CompareParaList.push(paraInfo_Extract)
    setG_CompareParaList(_CompareParaList)

    F_SetDiaPage(0);  /** Close the selection dialog */
  }
  

  const F_ShiftUp_FinalList=(idx)=>{
    let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
    _GraphParaList = F_Arr_ShiftUp(_GraphParaList, idx)
    setG_GraphParaList(_GraphParaList)
  }
  const F_ShiftDn_FinalList=(idx)=>{
    let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
    _GraphParaList = F_Arr_ShiftDn(_GraphParaList, idx)
    setG_GraphParaList(_GraphParaList)
  }
  const F_ShiftDel_FinalList=(idx)=>{
    let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
    _GraphParaList = F_Arr_ShiftDel(_GraphParaList, idx)
    setG_GraphParaList(_GraphParaList)
  }

  const F_ShiftDel_CompareList=(idx)=>{
    let _CompareParaList = JSON.parse(JSON.stringify(G_CompareParaList))
    _CompareParaList = F_Arr_ShiftDel(_CompareParaList, idx)
    setG_CompareParaList(_CompareParaList)
  }

  const F_RotInterval=()=>{
    let newInterval = F_RotatePivotInterval(G_sPivotInterval)
    setG_sPivotInterval(newInterval)
  }
  const F_RotStatMeasure=()=>{
    let newStatMeasure = F_RotateStatisticalMeasure(G_sStatMeasure)
    setG_sStatMeasure(newStatMeasure)
  }
  const F_ChooseTz=(info)=>{
    if (info?.name) setG_sTimezone(info.name)
    F_SetDiaPage(0);  /** Close the selection dialog */
  }
  

  
  
  return (
    <div className="Summer_EachPg ">
      <TpPgHeader
        headerTitle={"Daily Overlap Module"}
        onClick_Back={() => F_ToPage(0)}
      />

      {G_nSelectionPg === 1 && <TpChooseSensorWithBd
        onClickCancel={()=>F_SetDiaPage(0)} 
        onClickSelItem={F_AddPara}
      />}

      {G_nSelectionPg === 2 && <TpChooseSensorWithBd
        onClickCancel={()=>F_SetDiaPage(0)} 
        onClickSelItem={F_AddComparePara}
      />}

      
      {G_nSelectionPg===3 && <TpSelection 
        titleName={'Time Zone'}
        onClickCancel={()=>F_SetDiaPage(0)} 
        disName = {'name'}
        onClickSelItem = {F_ChooseTz}        
        selList={F_AllTimeZone()}
        />}

      {/* {G_DiaPage === 1 && <TpSelection onClickCancel={()=>F_SetDiaPage(0)}/>}
      <div className="Summer_PageLayout"></div> */}
      <div className="Summer_PageLayout">
        <div className="Summer_ModuleContainer">
          <div className="Summer_ModuleSubItemContainer">
            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Name :</div>
              <div className="Summer_Info_Value">
                <input
                  id="in_do_name"
                  className="Summer_info_input"
                  placeholder="<Insert Name>"
                  onChange={(e)=>F_SetName_Date(e, 1)}
                />
              </div>
            </div>

            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Start:</div>
              <div>
                <input
                  id="in_do_start"
                  className="Summer_TimeInput"
                  type="date"
                  onChange={(e)=>F_SetName_Date(e, 2)}
                />
              </div>
            </div>

            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">End:</div>
              <div className="">
                <input
                  id="in_do_end"
                  className="Summer_TimeInput"
                  type="date"
                  onChange={(e)=>F_SetName_Date(e, 3)}
                />
              </div>
            </div>
            
            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Timezone:</div>
              <div className="hoverPointer" onClick={()=>F_SetDiaPage(3)}>
                {G_sTimezone===""?
                  <div className="Summer_PlaceHolderColor">{`<Choose Time Zone>`}</div>:
                  <div className="Summer_info_input">{G_sTimezone}</div>}
              </div>
            </div>

            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Pivot interval:</div>
              <div style={{ marginRight: "2px" }} className="hoverPointer" onClick={F_RotInterval}>
                <div className="Summer_SubcontainerSelButton">{G_sPivotInterval}</div>
              </div>
            </div>

            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Statistical measure:</div>
              <div style={{ marginRight: "2px" }} className="hoverPointer" onClick={F_RotStatMeasure}>
                <div className="Summer_SubcontainerSelButton">{G_sStatMeasure}</div>
              </div>
            </div>


            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Display non-alphabet characters:</div>
              <div style={{ marginRight: "2px" }} className="hoverPointer">
                <TpToggleButton
                  status={G_bMultiLanguage}
                  onClick_in={() => F_Toggle("MultiLanguage")}
                />
              </div>
            </div>

            <div className="sortHor Summer_InfoKeyInSlot">
              <div className="Summer_Info_Title">Group by week:</div>
              <div style={{ marginRight: "2px" }} className="hoverPointer">
                <TpToggleButton
                  status={G_GroupByWeek}
                  onClick_in={() => F_Toggle("GroupByWeek")}
                />
              </div>
            </div>
          </div>

          <div className="Summer_ModuleSubItemContainer">            
            <div className="Summer_SubcontainerTitle">
              Control Limit
            </div>         

            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                onClick={() => F_Toggle("Toggle_UCL")}
              >
                {G_ModuleCfg?.bUCL ? <FaCheck /> : ""}
              </div>
              <div>UCL - Upper Control Limit</div>
              {G_ModuleCfg?.bUCL ? (
                <input type="number" id="in_do_ucl" className="stayRight Summer_info_input" placeholder="<Insert value>"
                  onChange={(e) => F_SetValue(e, "UCL")}
                />) : (<div></div>)}
            </div>

            {G_ModuleCfg?.bUCL===true && Array.isArray(G_ModuleCfg?.UCL_List) && G_ModuleCfg?.UCL_List.map((item, ind) => (<div key={`UCL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" 
              onClick={()=>F_ToggleCuz_xCL("UCL", ind)}
              >{item?.selected?<FaCheck/>:<div/>}</div>
                <div>{`Customize UCL ${ind+1}`}</div>
                {item?.selected?<div className="stayRight">
                  <TpTimeRangeAndValue config={item} className=""
                  listIdx = {ind}
                  onChange_SetStart={F_SetStart}
                  xCL = "UCL"
                  unique={`UCL_${ind}`}
                  />
                </div>:<div/>}
            </div>
            ))}

            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                onClick={() => F_Toggle("Toggle_CL")}
              >
                {G_ModuleCfg?.bCL ? <FaCheck /> : ""}
              </div>
              <div>CL - Control Limit</div>
              {G_ModuleCfg?.bCL ? (
                <input type="number" id="in_do_cl" className="stayRight Summer_info_input" placeholder="<Insert value>"
                  onChange={(e) => F_SetValue(e, "CL")}
                />) : (<div></div>)}
            </div>

            {G_ModuleCfg?.bCL===true && Array.isArray(G_ModuleCfg?.CL_List) && G_ModuleCfg?.CL_List.map((item, ind) => (<div key={`CL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" 
              onClick={()=>F_ToggleCuz_xCL("CL", ind)}
              >{item?.selected?<FaCheck/>:<div/>}</div>
                <div>{`Customize CL ${ind+1}`}</div>
                {item?.selected?<div className="stayRight">
                  <TpTimeRangeAndValue config={item} className=""
                  listIdx = {ind}
                  onChange_SetStart={F_SetStart}
                  xCL = "CL"
                  unique={`CL_${ind}`}
                  />
                </div>:<div/>}
            </div>
            ))}



            

            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                onClick={() => F_Toggle("Toggle_LCL")}
              >
                {G_ModuleCfg?.bLCL ? <FaCheck /> : ""}
              </div>
              <div>LCL - Lower Control Limit</div>
              {G_ModuleCfg?.bLCL ? (
                <input type="number" id="in_do_lcl" className="stayRight Summer_info_input" placeholder="<Insert value>"
                  onChange={(e) => F_SetValue(e, "LCL")}
                />) : (<div></div>)}
            </div>

            
            {G_ModuleCfg?.bLCL===true && Array.isArray(G_ModuleCfg?.LCL_List) && G_ModuleCfg?.LCL_List.map((item, ind) => (<div key={`LCL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" 
              onClick={()=>F_ToggleCuz_xCL("LCL", ind)}
              >{item?.selected?<FaCheck/>:<div/>}</div>
                <div>{`Customize LCL ${ind+1}`}</div>
                {item?.selected?<div className="stayRight">
                  <TpTimeRangeAndValue config={item} className=""
                  listIdx = {ind}
                  onChange_SetStart={F_SetStart}
                  xCL = "LCL"
                  unique={`LCL_${ind}`}
                  />
                </div>:<div/>}
            </div>
            ))}

          </div>

          <div className="Summer_ModuleSubItemContainer">
            <div className="Summer_SubcontainerTitle">
              Highlight
            </div>
            
            {Array.isArray(G_ModuleCfg?.HighLight_List) && G_ModuleCfg?.HighLight_List.map((item, ind) => (<div key={`LCL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                onClick={()=>F_ToggleCuz_xCL("HighLight", ind)}
              >                
                {item?.selected? <FaCheck /> : ""}
              </div>
              <div>{`Highlight ${ind+1}`}</div>
              {item?.selected?<div className="stayRight">
                <TpTimeRangeAndValue 
                  config={item} className=""
                  listIdx = {ind}
                  onChange_SetStart={F_SetStart}
                  xCL = "HighLight"
                  unique={`HighLight_${ind}`}
                  bHideValue  = {true}
                  />
              </div>:<div/>}
            </div>
            ))}
          </div>

          <div className="Summer_ModuleSubItemContainer">
            <div className="Summer_SubcontainerTitle">
              Scale limit
            </div>

            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                onClick={() => F_Toggle("Y_HiLimit")}
              >
                {G_ModuleCfg?.bY_Hi ? <FaCheck /> : ""}
              </div>
              <div>Y Axis, Hi Limit</div>
              {G_ModuleCfg?.bY_Hi ? (
                <input type="number" className="stayRight Summer_info_input" placeholder="<Insert value>"
                  onChange={(e) => F_SetValue(e, "Y_HiLimit")}
                />) : (<div></div>)}
            </div>
            
            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                onClick={() => F_Toggle("Y_LoLimit")}
              >
                {G_ModuleCfg?.bY_Lo ? <FaCheck /> : ""}
              </div>
              <div>Y Axis, Lo Limit</div>
              {G_ModuleCfg?.bY_Lo ? (
                <input type="number" className="stayRight Summer_info_input" placeholder="<Insert value>"
                  onChange={(e) => F_SetValue(e, "Y_LoLimit")}
                />) : (<div></div>)}
            </div>
          </div>

          <div className="Summer_ModuleSubItemContainer">            
            <div className="Summer_SubcontainerTitle">
              Graph Parameter
            </div>

            {G_GraphParaList.map((item, ind) => (
              <div key={`key_chiDpm_${ind}`} className="Summer_DaReq_DevList sortHor">
                <div>{`${ind+1}.`}</div>
                <div className="Summer_DaReq_Item_Name sortHor " style={{minWidth:"10px"}}>
                  <div className="wrapTextEllipsis" >{`${item?.bdInfo?.name}`}</div>
                  <div className="Summer_DaReq_LevelSeperator wrapTextEllipsis">{">"}</div>
                  <div className="wrapTextEllipsis" >{`${item?.sensorInfo?.name}`}</div>
                  <div className="Summer_DaReq_LevelSeperator wrapTextEllipsis">{">"}</div>
                  <div className="wrapTextEllipsis" >{`${item?.paraInfo?.dataName}`}</div>
                </div>            
                <div className="stayRight sortHor"> 
                  <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                  onClick={()=>F_ShiftUp_FinalList(ind)}
                  ><IoArrowUp/></div>
                  <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                  onClick={()=>F_ShiftDn_FinalList(ind)}
                  ><IoArrowDown/></div>
                  <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                  onClick={()=>F_ShiftDel_FinalList(ind)}
                  ><RiDeleteBinLine/></div>                
                </div>  
              </div>
            ))}
            <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_SetDiaPage(1)}>Add +</div>           

            {/* <div className="sortHor Summer_ModuleCfg_Item hoverPointer"
            onClick={()=>F_SetDiaPage(1)}>
              Choose Sensor
            </div> */}
          </div>

          <div className="Summer_ModuleSubItemContainer">            
            <div className="Summer_SubcontainerTitle">
              Compare Parameter (Optional)
            </div>

            {G_CompareParaList.map((item, ind) => (
              <div key={`key_comparePara_${ind}`} className="Summer_DaReq_DevList sortHor">
                <div>{`${ind+1}.`}</div>
                <div className="Summer_DaReq_Item_Name sortHor " style={{minWidth:"10px"}}>
                  <div className="wrapTextEllipsis" >{`${item?.bdInfo?.name}`}</div>
                  <div className="Summer_DaReq_LevelSeperator wrapTextEllipsis">{">"}</div>
                  <div className="wrapTextEllipsis" >{`${item?.sensorInfo?.name}`}</div>
                  <div className="Summer_DaReq_LevelSeperator wrapTextEllipsis">{">"}</div>
                  <div className="wrapTextEllipsis" >{`${item?.paraInfo?.dataName}`}</div>
                </div>            
                <div className="stayRight sortHor"> 
                  {/* <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                  onClick={()=>F_ShiftUp_FinalList(ind)}
                  ><IoArrowUp/></div>
                  <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                  onClick={()=>F_ShiftDn_FinalList(ind)}
                  ><IoArrowDown/></div> */}
                  <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                  onClick={()=>F_ShiftDel_CompareList(ind)}
                  ><RiDeleteBinLine/></div>                
                </div>  
              </div>
            ))}
            {G_CompareParaList.length<=0 && <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_SetDiaPage(2)}>Add +</div>           }

            {/* <div className="sortHor Summer_ModuleCfg_Item hoverPointer"
            onClick={()=>F_SetDiaPage(1)}>
              Choose Sensor
            </div> */}
          </div>

          <div className="Summer_DaReq_SendButton hoverPointer" 
            onClick={F_SendToAnalysis}
          >
            Send
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module0002DialyOverlap;
