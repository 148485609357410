import http from "../../httpService";

async function F_InsertDaReq(module_id, reqInfo, sensorList, cfgPara) {
  let url = process.env.REACT_APP_DASERVER_URL + "/damodule/dareq/insert";
  let body = { module_id, reqInfo, sensorList, cfgPara};
  return await http.post(url, body);
}

async function F_InsertDaReq_R02(module_id, moduleInfo) {
  let url = process.env.REACT_APP_DASERVER_URL + "/damodule/dareq/insert/R02";
  let body = { module_id, moduleInfo};
  return await http.post(url, body);
}


async function F_GetReqList(user_id) {
  let url =
    process.env.REACT_APP_DASERVER_URL + "/damodule/reldownload/readylist";
  let body = { user_id };
  return await http.post(url, body);
}

async function F_DownlaodDaZip(_id, unix, processState) {
  let url = process.env.REACT_APP_DASERVER_URL + "/damodule/reldownload/getzip";
  let body = { _id, unix, processState };
  return await http.post(url, body, {
    responseType: "blob",
  });
}


// reldownload/readylist
// reldownload/getzip

const expModule = {
  F_InsertDaReq,
  F_GetReqList,
  F_DownlaodDaZip,
  F_InsertDaReq_R02,
};
export default expModule;
