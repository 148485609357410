import http from "../../httpService";


async function getParaByTypeList(typeList) {   
    ///devrec/bd/getdevicesinbd
    let url = process.env.REACT_APP_MAINSERVER_URL + "/sensormng/getparabytylist";
    let body = {typeList};
    return await http.post(url, body);  
}


const expModule = {
    getParaByTypeList,
  };
  export default expModule;
  