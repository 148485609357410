import http from "../../httpService";

async function F_GetRelatedBd_user_id(user_id) {
  let url =
    process.env.REACT_APP_MAINSERVER_URL + "/devrec/building/getrelated";
  let body = { user_id };
  return await http.post(url, body);
}

async function F_GetAllDevsInBd(user_id, bd_id) {
  ///devrec/bd/getdevicesinbd
  let url = process.env.REACT_APP_MAINSERVER_URL + "/devrec/bd/getdevicesinbd";
  let body = {
    user_id,
    bd_id,
  };
  return await http.post(url, body);
}

async function F_GetSharedBdDevsInBd(bd_id, user_id) {
    ///devrec/bd/getdevicesinbd
    let url = process.env.REACT_APP_MAINSERVER_URL + "/devrec/sharebddev/getby_userid_bdid";
    let body = {
      user_id,
      bd_id,
    };
    return await http.post(url, body);
  }

  

  
  
  
const expModule = {
  F_GetRelatedBd_user_id,
  F_GetAllDevsInBd,
  F_GetSharedBdDevsInBd,
};
export default expModule;
