import React from 'react'
import TpPgHeader from '../Common/TpPgHeader'
import { useNavigate } from 'react-router-dom';

const DaSelectionPage = () => {

    const navigate = useNavigate();

    const F_ToPage=(sPage)=>{
        if (sPage==="back") {
          navigate("/main"); 
        } else if (sPage==="ChooseModule") {
          navigate("/module/md/selection"); 
        }else if (sPage==="Result") {
          navigate("/module/reldownload"); 
        }
      }

  return (
    <div className='Summer_EachPg'>
      <TpPgHeader headerTitle={"Analysis"} onClick_Back={()=>F_ToPage("back")}/>

      <div className='Summer_PageLayout'>
        <div style={{marginTop:"40px"}}></div>
        <div>
            <div className='hoverPointer summer_daSelItem Summer_Shadow' onClick={()=>F_ToPage("ChooseModule")}>Request</div>
        </div>

        <div>
            <div className='hoverPointer summer_daSelItem Summer_Shadow' onClick={()=>F_ToPage("Result")}>Result</div>
        </div>

      </div>
    </div>
  )
}

export default DaSelectionPage
