import SensorSer from "../../../service/MainServer/Sensor/Sensor_Ser"

async function getParaByTypeList(tyList) {
    try {
        let rel = await SensorSer.getParaByTypeList(tyList);                
        if (rel.statusText ==="OK") return rel.data       
        else{
            let errMsg = "getParaByTypeList Error"
            if (rel.errMsg){
                errMsg = rel.errMsg
            }
            return {errMsg, rel}
        }
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

const expModule = {
    getParaByTypeList,
};
export default expModule;