import baDevSer from "../../../service/MainServer/BdAndDevices/BdAndDevices_Ser"

async function F_GetRelatedBd_user_id(user_id) {
    try {
        let rel = await baDevSer.F_GetRelatedBd_user_id(user_id);                
        if (rel.statusText ==="OK") return rel.data       
        else{
            let errMsg = "F_GetRelatedBd_user_id Error"
            if (rel.errMsg){
                errMsg = rel.errMsg
            }
            return {errMsg, rel}
        }
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function F_GetAllDevsInBd(user_id, bd_id) {
    try {
        let rel = await baDevSer.F_GetAllDevsInBd(user_id, bd_id);                
        if (rel.statusText ==="OK") return rel.data       
        else{
            let errMsg = "F_GetRelatedBd_user_id Error"
            if (rel.errMsg){
                errMsg = rel.errMsg
            }
            return {errMsg, rel}
        }
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}


const expModule = {
    F_GetRelatedBd_user_id,
    F_GetAllDevsInBd,
};
export default expModule;