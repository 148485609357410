import daReqSer from "../../../service/DaServer/DataAnalysisQuery/DaReq_Ser"
import {F_HandleHttpResult} from "../../CommonHttpFn/CommonHttpFn"

async function F_InsertDaReq(module_id, reqInfo, sensorList, cfgPara) {
    try {
        let rel = await daReqSer.F_InsertDaReq(module_id, reqInfo, sensorList, cfgPara);     
        return F_HandleHttpResult("F_InsertDaReq Error", rel)
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}

async function F_InsertDaReq_R02(module_id, moduleInfo) {
    try {
        let rel = await daReqSer.F_InsertDaReq_R02(module_id, moduleInfo);     
        return F_HandleHttpResult("F_InsertDaReq_R02 Error", rel)
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}


async function F_GetReqList(user_id) {
    try {
        let rel = await daReqSer.F_GetReqList(user_id);  
        return F_HandleHttpResult("F_InsertDaReq Error", rel)
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}
async function F_DownlaodDaZip(_id, unix, processState) {
    try {
        let rel = await daReqSer.F_DownlaodDaZip(_id, unix, processState);     
        console.log("rel", rel);
        return rel;
        // return F_HandleHttpResult("F_InsertDaReq Error", rel)
    } catch (error) {
        console.log(error.message);
        return {errMsg: "Server Error"};
    }
}



const expModule = {
    F_InsertDaReq,
    F_InsertDaReq_R02,
    F_GetReqList, 
    F_DownlaodDaZip
};
export default expModule;