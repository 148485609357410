export function sortTemplate(a,b, sortKeys){
    if(!Array.isArray(sortKeys)) return 
    for (const sortObj of sortKeys) {
      if(typeof(a[sortObj["key"]])==='string'){
        // console.log('Is String');
        if(a[sortObj["key"]].toUpperCase().trim() < b[sortObj["key"]].toUpperCase().trim()) return (sortObj["descOrder"]) ? 1:-1;
        if(a[sortObj["key"]].toUpperCase().trim() > b[sortObj["key"]].toUpperCase().trim()) return (sortObj["descOrder"]) ? -1:1;
      }else{
        // console.log('Is Not String');
        if(a[sortObj["key"]] < b[sortObj["key"]]) return (sortObj["descOrder"]) ? 1:-1;
        if(a[sortObj["key"]] > b[sortObj["key"]]) return (sortObj["descOrder"]) ? -1:1;
      }
    }
    return 0;
  }

export function isEmptyOrNotArray(arrIn){
  if (!Array.isArray(arrIn)) return true;
  if (arrIn.length <= 0) return true;
  return false
}

export function isArrayWithContent(arrIn){
  if (Array.isArray(arrIn) && arrIn.length>0) return true;  
  return false
}


export function F_Arr_ShiftUp (arr_Input, index) {
  let _TempList_Final = JSON.parse(JSON.stringify(arr_Input));

  // Ensure the index is within the valid range for shifting up
  if (index > 0 && index < _TempList_Final.length) {
    // Swap the element at the given index with the element before it
    let temp = _TempList_Final[index];
    _TempList_Final[index] = _TempList_Final[index - 1];
    _TempList_Final[index - 1] = temp;
  }

  return _TempList_Final    
};

export function F_Arr_ShiftDn (arr_Input, index) {
  let _TempList_Final = JSON.parse(JSON.stringify(arr_Input));

  // Ensure the index is within the valid range for shifting up
  if (index >= 0 && index < _TempList_Final.length-1) {
    // Swap the element at the given index with the element before it
    let temp = _TempList_Final[index];
    _TempList_Final[index] = _TempList_Final[index + 1];
    _TempList_Final[index + 1] = temp;
  }
  
  return _TempList_Final
};

export function F_Arr_ShiftDel (arr_Input, index) {
  let _TempList_Final = JSON.parse(JSON.stringify(arr_Input));

  if (index >= 0 && index < _TempList_Final.length) {
    _TempList_Final.splice(index, 1);
  }
  
  return _TempList_Final
};