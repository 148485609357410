import moment from "moment-timezone";

const timezones = moment.tz.names();

let arrObj_tz = []
for (const eachTz of timezones) {
  arrObj_tz.push({name:eachTz})
}

export const F_AllTimeZone = () => {
  return arrObj_tz
}

export const F_GenApiSensorList = (devInfo, varType, varIdx, bd_id) => {
    if ("_id" in devInfo && "type" in devInfo) {
      return {
        name:devInfo.name,
        bdDev_id: devInfo._id,
        ht: devInfo.type,
        varType,
        varIdx,
        bd_id,
      };
    }
    return null;
  };


let arrPivotInterval = [
  {idx:0, name: '10min'},
  {idx:1, name: '15min'},
  {idx:2, name: '20min'},
  {idx:3, name: '30min'},
  {idx:4, name: '1h'}
]

export const F_RotatePivotInterval = (sInterval) => {
  let arrPos = arrPivotInterval.findIndex(c=>c.name === sInterval.toLowerCase())
  if (arrPos < 0) return arrPivotInterval[0].name
  
  /** get next interval */
  let retPos = arrPos+1
  if (retPos>=arrPivotInterval.length) return arrPivotInterval[0].name

  return arrPivotInterval[retPos].name
}


let StatisticalMeasure = [  
  {idx:0, name: 'mean'},
  {idx:1, name: 'median'},
  {idx:2, name: 'min'},
  {idx:3, name: 'max'},
]

export const F_RotateStatisticalMeasure = (sStatic) => {
  let arrPos = StatisticalMeasure.findIndex(c=>c.name === sStatic.toLowerCase())
  if (arrPos < 0) return StatisticalMeasure[0].name
  
  /** get next interval */
  let retPos = arrPos+1
  if (retPos>=StatisticalMeasure.length) return StatisticalMeasure[0].name

  return StatisticalMeasure[retPos].name
}


let arrLineDisplay_Options=[
  {name:"Multi Line"},
  {name:"Sum Up"},
]
export const F_Rotate_LineDisplayOption=(inCurState)=>{
  let arrPos = arrLineDisplay_Options.findIndex(c=>c.name === inCurState)
  if (arrPos < 0) {
    if (arrLineDisplay_Options.length > 1) return arrLineDisplay_Options[1].name
    else return arrLineDisplay_Options[0].name
  }

  /** get next option */
  let retPos = arrPos+1
  if (retPos>=arrLineDisplay_Options.length) return arrLineDisplay_Options[0].name

  return arrLineDisplay_Options[retPos].name
}


export const F_GetLineDisplayOption=(inCurState)=>{
  let arrPos = arrLineDisplay_Options.findIndex(c=>c.name === inCurState)
  if (arrPos < 0) return arrLineDisplay_Options[0].name

  return arrLineDisplay_Options[arrPos].name
}

export const F_GetLineDisplayOption_byIdx=(nIdx)=>{
  let options = arrLineDisplay_Options[nIdx]
  if (options) return options?.name
  else return arrLineDisplay_Options[0]?.name
}
