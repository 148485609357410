// import logo from './logo.svg';
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// -----------redux -----------------
import cfgStore from './reduxStore/configureStore';
import {Provider} from 'react-redux';
//==========redux end===============

import UserLogin from "./component/page/user/userLogin";
// import TpProtectedRoute from './component/template/route/TpProtectedRoute';
// import TempMain from './component/tempMain';
import DownloadPg from "./component/page/download/downloadPg";
import "./css/MainTemplete/mainCss.css";
import "./css/MainTemplete/colorTemplate.css";
import "./css/MainTemplete/summer.css";
import "./css/colorPicker/reactColorful.css";
import { useState } from "react";
// import DaModulePg from './component/page/Da_Module/DaModulePg';
import AnalysticModulePage from "./component/page/Da_Module/AnalysticModulePage";
import DaResultDownloadPg from "./component/page/Da_Module/DaResultDownloadPg";
import MainPage from "./component/page/Da_Module/MainPage/MainPage";
import DaSelectionPage from "./component/page/Da_Module/DaSelectionPage";
import Module0002DialyOverlap from "./component/page/Da_Module/ModuleTemplate/MD_0002_DialyOverlap/Module_0002_DialyOverlap";
import MDSelectionPg from "./component/page/Da_Module/ModuleTemplate/01_MdSelectionPg/MD_SelectionPg";
import Module_0003_LineTrend from "./component/page/Da_Module/ModuleTemplate/MD_0003_LineTrend/Module_0003_LineTrend";

const store = cfgStore();

function App() {
  // eslint-disable-next-line
  const [G_NightMode, setG_NightMode] = useState(true);

  return (
    <Provider store={store}>
      <div
        className={`pageTemplate ${
          G_NightMode ? "GCssMode_Night" : "GCssMode_Day"
        }`}
      >
        <Routes>
          {/* <Route path="/" element={<TempMain />} /> */}
          <Route path="/download" element={<DownloadPg />} />
          <Route path="/user/login" element={<UserLogin />} />

          <Route path="/main" element={<MainPage />} />

          <Route path="/module/main" element={<DaSelectionPage />} />
          <Route path="/module/insert" element={<AnalysticModulePage />} />
          <Route path="/module/reldownload" element={<DaResultDownloadPg />} />

          <Route path="/module/md/selection" element={<MDSelectionPg />} />
          <Route
            path="/module/md/0001dailyoverlap"
            element={<Module0002DialyOverlap />}
          />
          <Route
            path="/module/md/0002linetrend"
            element={<Module_0003_LineTrend />}
          />

          {/* <Route path="/download" element={
          <TpProtectedRoute>
            <DownloadPg />
          </TpProtectedRoute>
        } />
        <Route path="/" element={
          <TpProtectedRoute>
            <TempMain />
          </TpProtectedRoute>
        } /> */}
          <Route path="*" element={<Navigate to="/main" replace />} />
        </Routes>
        <ToastContainer />
      </div>
    </Provider>
  );
}

export default App;
