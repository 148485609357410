import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from 'reselect';
// import { useDispatch } from 'react-redux';
// import api from "../middleware/api";
import { apiCallBegan } from './../middleware/api';
import timeFn from '../../function/time/timeFn';

const slice = createSlice({
    name:'Sensors',   
    initialState:{
        loadedBds:[],
        sensorList:{},
        paraList:{},
        bdLoadedUnix:0,
    },
    reducers:{
        addSensor:(state, action)=>{
            state.list = [...action.payload];
        }, 
        Rdx_setLoadedBd:(state, action)=>{
            state.loadedBds = [...action.payload];
            state.bdLoadedUnix = timeFn.getUnixNow()
        }, 
        Rdx_addBdSensorList:(state, action)=>{
            // state.sensorList = [...action.payload];
            let bdid_in = action.payload.bd_id
            let senList = action.payload.sensorList

            state.sensorList[`bd_id_${bdid_in}`] = senList
        }, 
        Rdx_addParaList:(state, action)=>{
            // state.sensorList = [...action.payload];
            let sensorType = action.payload.sensorType
            let paraList = action.payload.paraList

            state.paraList[`type_${sensorType}`] = paraList
        }, 
    }
})

/** Export Action */
export const {
    addSensor,
    Rdx_setLoadedBd,
    Rdx_addBdSensorList,
    Rdx_addParaList,
} = slice.actions;

/** reducer */
export default slice.reducer;

/** Action Creator, call action */
export const loadFriendList = ()=> apiCallBegan({
    onSuccess:addSensor.type,
})



/** Selector, read from store */
export const getFriendList = createSelector(
    state=>state.users.friendList.list,
    frList => frList
)

/** get bdLoadedUnix */
export const Rdx_getBdLoadedUnix = state => state.Selection.PickSensor.bdLoadedUnix;

// // Code below will cause error message
// export const Rdx_getBdLoadedUnix = createSelector(
//     state=>state.Selection.PickSensor.bdLoadedUnix,
//     LoadedUnix => LoadedUnix
// )

/** get BD list */
export const Rdx_getBdLoadedList = createSelector(
    state=>state.Selection.PickSensor.loadedBds,
    loadedBds => [...loadedBds]
)


/** Selector to check if ID exists in loadedBds */
export const isIdInLoadedBds = createSelector(
    state => state.Selection.PickSensor.loadedBds,
    (state, asd) => asd,
    (loadedBds, id) => {
        return loadedBds.includes(id)
    }
);

/** Selector to check if building sensor pre loaded */
export const Rdx_bdSensorLoaded = state => state.Selection.PickSensor.sensorList;


/** return para list */
export const Rdx_bdParaListLoaded = state => state.Selection.PickSensor.paraList;