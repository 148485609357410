import React, { useEffect } from "react";

const TpTimeRangeAndValue = (props) => {

  useEffect(() => {
    async function startUp() {
      // let relatedBd = await BD_Dev_Fn.F_GetRelatedBd_user_id(1)
      F_DisplayValue();
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, [props.config]);

  const F_SetStartTime=(event, paraType)=>{
    if (props.onChange_SetStart){
      props.onChange_SetStart(event, props.listIdx, paraType, props.xCL)
    }
  }

  const F_DisplayValue=()=>{    
    if (props?.config){
      document.getElementById(`${props.unique}_cus_start`).value = props?.config?.startTime
      document.getElementById(`${props.unique}_cus_end`).value = props?.config?.endTime
      if (!props?.bHideValue) document.getElementById(`${props.unique}_cus_value`).value = props?.config?.value
    }
    
  }


  return (
    <div className="">
      <div className="sortHor">
        {/* <div>{props.name?props.name:"Title"}</div> */}
        <div className="stayRight sortHor">
          <input
            id={`${props.unique}_cus_start`}
            className="Summer_TimeInput"
            type="time"
            onChange={(e)=>F_SetStartTime(e, "Start")}
          />
          <div style={{marginTop:"-3px", marginLeft:"10px", marginRight:"10px"}} >to</div>
          <input
            id={`${props.unique}_cus_end`}
            className="Summer_TimeInput"
            type="time"
            onChange={(e)=>F_SetStartTime(e, "End")}
          />
          {!props?.bHideValue && <input
            id={`${props.unique}_cus_value`}
            style={{ width: "100px" , marginLeft:"10px"}}
            type="number"
            className="Summer_info_input"
            placeholder="<Insert value>"
            onChange={(e)=>F_SetStartTime(e, "value")}
          />}
        </div>
      </div>
    </div>
  );
};

export default TpTimeRangeAndValue;
