import moment from 'moment';
import 'moment-timezone';
import {F_GenApiSensorList} from '../CommonFn/ModuleCommonFn'


export const F_GetReqInfo_Api_0001 = (
  bd_id,
  reqName,
  user_id,
  sTz,
  start,
  end,
  btuList,
  dpm_chList,
  dpm_chwpList,
  dpm_cdwpList
) => {
  let module_id =1;
  let sensorList = []
  /** handle time info <--------*/
  const startUnix = moment.tz(start, sTz).unix();  
  const endUnix = moment.tz(end, sTz).unix();
  let reqInfo ={
    module_id, reqName, user_id ,startUnix,endUnix
  }
  
  /** handle BTU */
  btuList.forEach((eachBtu, index) => {
    let singleSenInfo = F_GenApiSensorList(eachBtu, 1, index+1, bd_id);
    if (singleSenInfo) sensorList.push(singleSenInfo)
  });
  /** Chiller DPM */
  dpm_chList.forEach((eachDev, index) => {
    let singleSenInfo = F_GenApiSensorList(eachDev, 2, index+1, bd_id);
    if (singleSenInfo) sensorList.push(singleSenInfo)
  });
  /** CHWP DPM */
  dpm_chwpList.forEach((eachDev, index) => {
    let singleSenInfo = F_GenApiSensorList(eachDev, 3, index+1, bd_id);
    if (singleSenInfo) sensorList.push(singleSenInfo)
  });
  /** CDWP DPM */
  dpm_cdwpList.forEach((eachDev, index) => {
    let singleSenInfo = F_GenApiSensorList(eachDev, 4, index+1, bd_id);
    if (singleSenInfo) sensorList.push(singleSenInfo)
  });

  /**
     * 
	startUnix INT(11),	
	endUnix INT(11),
	module_id int,
	user_id int,
     reqName varchar(80),
	processState smallint,

	bdDev_id INT,	
	ht INT,	
	varType smallint,
	varIdx smallint,
	reqList_id int,

     */

  /** handle DPM */
  return {reqInfo, sensorList };
};
