import { useDispatch, useSelector } from "react-redux";
import BD_Dev_Fn from "../../../function/MainServer/BdAndDevices.jsx/BdAndDeviceFn";
import { Rdx_addBdSensorList, Rdx_addParaList, Rdx_bdParaListLoaded, Rdx_bdSensorLoaded, Rdx_getBdLoadedList, Rdx_getBdLoadedUnix, Rdx_setLoadedBd } from "../../../reduxStore/actions/sensor_list";
import timeFn from "../../../function/time/timeFn"
import Sensor_Fn from "../../../function/MainServer/Sensor/SensorFn"

const RdxLoadBd=()=>{
    const G_bDebugLog = false        // ??? 
    const dispatch = useDispatch();  

    const R_BdLoadedUnix = useSelector(Rdx_getBdLoadedUnix);
    const R_BdList = useSelector(Rdx_getBdLoadedList);
    const R_SensorList = useSelector(Rdx_bdSensorLoaded);
    const R_ParaList = useSelector(Rdx_bdParaListLoaded);
    
    
    const user_id = 1; // temporary hard code ???

    const F_LoadBdToRedux=async ()=>{        
        /** check bd loading time, if more than certain time, reload */
        let timeNow = timeFn.getUnixNow();
        if (R_BdLoadedUnix !==0 && timeNow > R_BdLoadedUnix + (60*60)){            
            /** else, return done = true. */
            if (G_bDebugLog) console.log("BD loaded, skip reload");            
            return R_BdList
        }
        /** call API to laod BD list. */
        let relatedBd = await BD_Dev_Fn.F_GetRelatedBd_user_id(user_id);
        if (relatedBd.errMsg) return {errMsg:"Load Building List Error"}
    
        /**  store BD list into */
        dispatch(Rdx_setLoadedBd(relatedBd))
        return relatedBd    
    }

    const F_GenSensorName=(bd_id)=>{
        return `bd_id_${bd_id}`
    }
    const F_bCheckBdSensorLoaded=(bd_id)=>{
        return (F_GenSensorName(bd_id) in R_SensorList) 
    }
    const F_GetBdSensorList = (bd_id)=>{
        return R_SensorList[F_GenSensorName(bd_id)]
    }

    const F_GenParaName=(type)=>{
        return `type_${type}`
    }
    const F_bCheckSensorParaLoaded=(sensorType)=>{
        return (F_GenParaName(sensorType) in R_ParaList)
    }
    const F_GetSensorParaList = (sensorType)=>{
        return R_ParaList[F_GenParaName(sensorType)]
    }

    const F_LoadSensorToRedux=async (bd_id)=>{
        /** Check selected building sensor list whether available */
        let bExist = F_bCheckBdSensorLoaded(bd_id);
        if (bExist){
            if (G_bDebugLog) console.log("BD Sensor loaded before, skip");
            return F_GetBdSensorList(bd_id)
        }

        let devs = await BD_Dev_Fn.F_GetAllDevsInBd(user_id, bd_id);
        dispatch(Rdx_addBdSensorList({bd_id, sensorList: devs}))
        /** save the sensor list into redux */
        return devs
    }

    const F_rdx_LoadSensorPara=async(typeList)=>{
        /** check if selected sensor type's para list already loaded */
        let sensorType = typeList[0]
        let bExist = F_bCheckSensorParaLoaded(sensorType)
        if (bExist){
            if (G_bDebugLog) console.log("Para Exist, skip loading");
            return F_GetSensorParaList(sensorType)
        }

        let paraList = await Sensor_Fn.getParaByTypeList(typeList)
        dispatch(Rdx_addParaList({sensorType, paraList}))
        
        return paraList
    }

    return { F_LoadBdToRedux , F_LoadSensorToRedux, F_rdx_LoadSensorPara};

}


export default RdxLoadBd;