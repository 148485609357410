import React from 'react'
import { TbPointFilled } from "react-icons/tb";
import { FaCheck } from "react-icons/fa";

const TpScatterCfg = (props) => {

  const F_ToggleSel=(func)=>{    
    if (props.fToggle) {      
      props.fToggle(props.setIdx, func)
    }
  }

  const F_SetValue = (event, func)=>{
    if (props.fSetValue){
      props.fSetValue(event, props.setIdx, func)
    }
  }
  
  return (
    <div>
        <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleSel(1)}
                >{props.curConfig?.sel?<FaCheck/>:<div/>}</div>
            <div>{props.graphName}</div>
        </div>
        {props.curConfig?.sel?<div>
            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleSel(2)}
                  >{props.curConfig?.maxRal?.sel?<FaCheck/>:<div/>}</div>                  
              <div>Max RLA</div>
              {props.curConfig?.maxRal?.sel?<input type="number" onChange={(e)=>F_SetValue(e, 2)}
              
              className="stayRight Summer_info_input" placeholder="<Insert value>"/>:<div/>}
            </div>

            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleSel(3)}
                  >{props.curConfig?.y_max?.sel?<FaCheck/>:<div/>}</div>
                  
              <div>Y Max</div>
              {props.curConfig?.y_max?.sel?<input type="number" onChange={(e)=>F_SetValue(e, 3)}
              
              className="stayRight Summer_info_input" placeholder="<Insert value>"/>:<div/>}
            </div>

            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleSel(4)}
                  >{props.curConfig?.y_min?.sel?<FaCheck/>:<div/>}</div>
                  
              <div>Y Min</div>
              {props.curConfig?.y_min?.sel?<input type="number" onChange={(e)=>F_SetValue(e, 4)}
              
              className="Summer_info_input stayRight" placeholder="<Insert value>"/>:<div/>}
            </div>
            
            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleSel(5)}
                  >{props.curConfig?.x_max?.sel?<FaCheck/>:<div/>}</div>
                  
              <div>X Max</div>
              {props.curConfig?.x_max?.sel?<input type="number" onChange={(e)=>F_SetValue(e, 5)}
              
              className="Summer_info_input stayRight" placeholder="<Insert value>"/>:<div/>}
            </div>
            
            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleSel(6)}
                  >{props.curConfig?.x_min?.sel?<FaCheck/>:<div/>}</div>
                  
              <div>X Min</div>
              {props.curConfig?.x_min?.sel?<input type="number" onChange={(e)=>F_SetValue(e, 6)}
              
              className="Summer_info_input stayRight" placeholder="<Insert value>"/>:<div/>}
            </div>
          </div>:<div/>}
        </div>
  )
}

export default TpScatterCfg
