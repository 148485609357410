import React from 'react'
import TpPgHeader from '../../../Common/TpPgHeader'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MDSelectionPg = () => {

  const navigate = useNavigate();

  const G_Arr_Moduel=[
    {name:"Chiller Efficiency", link:"/module/insert"},
    {name:"Daily Overlap", link:"/module/md/0001dailyoverlap"},
    {name:"Line Trend", link:"/module/md/0002linetrend"},
  ]

  const F_ToPage=(pgLink)=>{
    if (pgLink===0) {
      navigate("/module/main"); 
    }else{
      if (pgLink === null || pgLink === "" || pgLink === undefined) return toast("Invalid Link")
      navigate(pgLink); 
    }

  }

  return (
    <div className="Summer_EachPg ">
      <TpPgHeader headerTitle={"Module Selection"} onClick_Back={()=>F_ToPage(0)}/>
      {/* {G_DiaPage === 1 && <TpSelection onClickCancel={()=>F_SetDiaPage(0)}/>}
      <div className="Summer_PageLayout"></div> */}

        {G_Arr_Moduel.map((item, ind) => (
          <div key={`MdSelection_${ind}`} className="hoverPointer Summer_SelBox Summer_Shadow"
          onClick={()=>F_ToPage(item?.link)}>            
            <div>{item.name}</div>
          </div>
        ))}
    </div>
  )
}

export default MDSelectionPg
