import React, { useEffect, useRef, useState } from "react";
import BD_Dev_Fn from "../../../../../function/MainServer/BdAndDevices.jsx/BdAndDeviceFn";
// import moment from "moment-timezone";
import daReqFn from "../../../../../function/DaServer/DataAnalysisQuery/DaReq_Fn";
import { F_GetReqInfo_Api_0001 } from "./Module_0001_ReqApi";
import TpSelection from './../../../Common/TpSelection';
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import { TbPointFilled } from "react-icons/tb";
import TpGraphConfig from "./TpGraphConfig";
import TpScatterCfg from "./TpScatterCfg";

const MdChEffi0001 = () => {  
  const ReactName_kW_Cdwp = "kW - CDWP"
  const ReactName_kw_Cdwp_Total = "kW - CDWP Total"
  const ReactName_kW_Chillers = "kW - Chiller"
  const ReactName_kW_Ch_Total = "kW - Chiller Total"
  const ReactName_kW_Chwp = "kW - CHWP"
  const ReactName_kW_Chwp_Total = "kW - CHWP Total"
  const ReactName_Effi = "Effi - System"
  const ReactName_Effi_EachCh = "Effi - Each Chiller"
  const ReactName_TR_Total = "TR - Total"
  const ReactName_Tr_EachCh = "TR - Single Chiller"




  const G_ChScatterPrefixName = "Chiller set"
  

  const G_Graph_Init ={sel:false, hiSel:false, loSel:false, hi:"", lo:""};  
  const G_ScatterInit = {
    sel:false, 
    maxRal:{sel:false, value:NaN}, 
    y_max:{sel:false, value:NaN}, 
    y_min:{sel:false, value:NaN}, 
    x_max:{sel:false, value:NaN}, 
    x_min:{sel:false, value:NaN}}
  const user_id = 1;
  // const data = [
  //   { id: 1, channel: "CH 1", value1: 329, value2: 47 },
  //   { id: 2, channel: "CH 2", value1: 330, value2: 47 },
  // ];
  
  const navigate = useNavigate();

  const [G_BdList, setG_BdList] = useState([]);
  const [G_BdObj_Sel, setG_BdObj_Sel] = useState({});   /** remove later */
  const [G_BdIDSelected, setG_BdIDSelected] = useState(0);   /** remove later */

  const [G_DPMList, setG_DPMList] = useState([]);
  // const [G_DPMInOption, setG_DPMInOption] = useState([]);
  const [G_DPMList_Final, setG_DPMList_Final] = useState([]);

  const [G_BTUList, setG_BTUList] = useState([]);
  // const [G_BTUInOption, setG_BTUInOption] = useState([]);
  const [G_BTUList_Final, setG_BTUList_Final] = useState([]);

  // const [G_DPMList, setG_CHWPList] = useState([]);
  // const [G_CHWPInOption, setG_CHWPInOption] = useState([]);
  const [G_CHWPList_Final, setG_CHWPList_Final] = useState([]);

  // const [G_DPMList, setG_CDWPList] = useState([]);
  // const [G_CDWPInOption, setG_CDWPInOption] = useState([]);
  const [G_CDWPList_Final, setG_CDWPList_Final] = useState([]);

  const [G_StartTime, setG_StartTime] = useState("");
  const [G_EndTime, setG_EndTime] = useState("");
  // eslint-disable-next-line
  const [G_TimeZone, setG_TimeZone] = useState("Asia/Kuala_Lumpur");
  const [G_ModuleName, setG_ModuleName] = useState("");

  
  const [G_nSelectionPg, setG_nSelectionPg] = useState(0);

  const [G_Cfg_Sel_TrInvert, setG_Cfg_Sel_TrInvert] = useState(true);
  const [G_Cfg_Sel_TrHiPass, setG_Cfg_Sel_TrHiPass] = useState(false);
  const [G_Cfg_Sel_TrLoPass, setG_Cfg_Sel_TrLoPass] = useState(false);
  const [G_Cfg_Sel_TrLoPassValue, setG_Cfg_Sel_TrLoPassValue] = useState("");
  const [G_Cfg_Sel_TrHiPassValue, setG_Cfg_Sel_TrHiPassValue] = useState("");

  const [G_Cfg_Graph_Effi, setG_Cfg_Graph_Effi] = useState(G_Graph_Init);
  const [G_Cfg_Graph_kW_CDWP, setG_Cfg_Graph_kW_CDWP] = useState(G_Graph_Init);
  const [G_Cfg_Graph_Tr_Total, setG_Cfg_Graph_Tr_Total] = useState(G_Graph_Init);

  const [G_Cfg_Graph_kW_Chillers, setG_Cfg_Graph_kW_Chillers] = useState(G_Graph_Init);
  const [G_Cfg_Graph_kW_Chwp , setG_Cfg_Graph_kW_Chwp] = useState(G_Graph_Init);
  const [G_Cfg_Graph_kW_Cdwp , setG_Cfg_Graph_kW_Cdwp] = useState(G_Graph_Init);
  const [G_Cfg_Graph_kW_Ch_Total, setG_Cfg_Graph_kW_Ch_Total] = useState(G_Graph_Init);
  const [G_Cfg_Graph_kW_Chwp_Total, setG_Cfg_Graph_kW_Chwp_Total] = useState(G_Graph_Init);
  const [G_Cfg_Graph_Tr_EachCh, setG_Cfg_Graph_Tr_EachCh] = useState(G_Graph_Init);
  const [G_Cfg_Graph_Effi_EachCh, setG_Cfg_Graph_Effi_EachCh] = useState(G_Graph_Init);
  
  const [G_Cfg_Scatter_CHs, setG_Cfg_Scatter_CHs] = useState([]);

  const refPageClose = useRef(false);

  useEffect(() => {
    async function startUp() {
      /** get and set related BD */
      let relatedBd = await BD_Dev_Fn.F_GetRelatedBd_user_id(user_id);
      if (!relatedBd.errMsg) {
        setG_BdList(relatedBd);
      }
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      refPageClose.current = true;
    };
    // eslint-disable-next-line
  }, []);

  // const F_BdSelected = async (event) => {
  //   const selectedValue = event.target.value;
  //   const selected = G_BdList.find(
  //     (item) => item._id === parseInt(selectedValue)
  //   );

  //   await F_LoadDevsInBd(user_id, selected._id);
  //   setG_BdIDSelected(selected._id)
  // };

  // const F_DPMSelected = (event) => {
  //   const selectedValue = event.target.value;
  //   const selected = G_DPMList.find(
  //     (item) => item._id === parseInt(selectedValue)
  //   );
  //   setG_DPMInOption(selected);
  // };

  // const F_CHWPSelected = (event) => {
  //   const selectedValue = event.target.value;
  //   const selected = G_DPMList.find(
  //     (item) => item._id === parseInt(selectedValue)
  //   );
  //   setG_CHWPInOption(selected);
  // };

  // const F_CDWPSelected = (event) => {
  //   const selectedValue = event.target.value;
  //   const selected = G_DPMList.find(
  //     (item) => item._id === parseInt(selectedValue)
  //   );
  //   setG_CDWPInOption(selected);
  // };

  // const F_BTUSelected = (event) => {
  //   const selectedValue = event.target.value;
  //   const selected = G_BTUList.find(
  //     (item) => item._id === parseInt(selectedValue)
  //   );
  //   setG_BTUInOption(selected);
  // };

  const F_SetModuleName = (event) => {
    const selectedValue = event.target.value;
    setG_ModuleName(selectedValue);
  };

  const F_AddDpm = (devInfo_List) => { 
    // let _DPMList_Final=[]
    let _DPMList_Final = JSON.parse(JSON.stringify(G_DPMList_Final));
    _DPMList_Final.push(...devInfo_List);

    setG_DPMList_Final(_DPMList_Final);
    F_CallSel(0);

    F_GetChPairCount(G_BTUList_Final, _DPMList_Final)
    
  };

  const F_GetChPairCount=(arrBtu, arrDpm)=>{
    let btuLen = arrBtu.length;
    let dpmLen = arrDpm.length;
    let pairLen = 0;
    if(btuLen>= dpmLen) pairLen = dpmLen
    pairLen = btuLen;
    
    // G_Cfg_Scatter_CHs  G_ScatterInit
    let _Cfg_Scatter_CHs = JSON.parse(JSON.stringify(G_Cfg_Scatter_CHs));
    
    if(_Cfg_Scatter_CHs.length>pairLen){  // pair len less, need to splice
      _Cfg_Scatter_CHs = _Cfg_Scatter_CHs.slice(0, pairLen)
    }else if (_Cfg_Scatter_CHs.length<pairLen){ // pair len more, need to append.
      while (_Cfg_Scatter_CHs.length<pairLen) {
        _Cfg_Scatter_CHs.push(G_ScatterInit)
      }
    }
    setG_Cfg_Scatter_CHs(_Cfg_Scatter_CHs);
    
        
  }
  const F_AddBtu = (devInfo) => {   // Add btu
    let _BTUList_Final = JSON.parse(JSON.stringify(G_BTUList_Final));
    _BTUList_Final.push(...devInfo);

    setG_BTUList_Final(_BTUList_Final);
    F_CallSel(0);

    F_GetChPairCount(_BTUList_Final, G_DPMList_Final)
    
    
    
  };

  const F_AddChwp = (devInfo) => {
    let _CHWPList_Final = JSON.parse(JSON.stringify(G_CHWPList_Final));
    _CHWPList_Final.push(...devInfo);

    setG_CHWPList_Final(_CHWPList_Final);
    F_CallSel(0);
  };

  const F_AddCdwp = (devInfo) => {
    let _CDWPList_Final = JSON.parse(JSON.stringify(G_CDWPList_Final));
    _CDWPList_Final.push(...devInfo);

    setG_CDWPList_Final(_CDWPList_Final);
    F_CallSel(0);
  };

  const F_LoadDevsInBd = async (user_id, bd_id) => {
    let devs = await BD_Dev_Fn.F_GetAllDevsInBd(user_id, bd_id);
    /** get dpm list */
    let _DPMList = devs.filter((c) => c.type === 50);
    setG_DPMList(_DPMList);

    /** get BTU list */
    let _BTUList = devs.filter((c) => c.type === 51);
    setG_BTUList(_BTUList);
  };

  const F_SetStartTime = (event) => {
    setG_StartTime(event.target.value);
  };
  const F_SetEndTime = (event) => {
    setG_EndTime(event.target.value);
  };


  const F_SendToAnalysis = async () => {
    // const timezones = getTimezones();
    /** validation */
    // if (G_ModuleName.trim()==="") return toast("Name not insert") 
    // if (parseInt(G_BdIDSelected)<=0) return toast("Building not selected") 
    // if (G_StartTime.trim()==="") return toast("Start time not set") 
    // if (G_EndTime.trim()==="") return toast("End time not set") 
    // if (G_EndTime <= G_StartTime) return toast("Invalid time range")
    // if (isEmptyOrNotArray(G_DPMList_Final)) return toast("Please add Chiller DPM")
    // if (isEmptyOrNotArray(G_BTUList_Final)) return toast("Please add CH BTU")
    let {validPass, cfgPara} = F_ValidateConfigPara()
    if (!validPass) return 
    /** validate scatter para */
    let {validPass:ScatterPass, scatterInfo}= F_ValidateScatterPara();
    
    if (!ScatterPass) return 
    cfgPara.chScatterInfo = scatterInfo;
    // console.log("cfgPara", cfgPara);
    // return

    let reqInfo = F_GetReqInfo_Api_0001(
      G_BdIDSelected,
      G_ModuleName,
      user_id,
      G_TimeZone,
      G_StartTime,
      G_EndTime,
      G_BTUList_Final,
      G_DPMList_Final,
      G_CHWPList_Final,
      G_CDWPList_Final
    );
    // return toast("Break")
    // return 
    let reqRel = await daReqFn.F_InsertDaReq(1, reqInfo.reqInfo, reqInfo.sensorList, cfgPara);   // ??? Replace 1 to respective user id
    if (reqRel.errMsg) return toast(`Error: ${reqRel.errMsg}`)
    
    toast("Send Query Success")
    navigate("/main"); 
  };

  const F_ShiftUp_FinalList = (index, listType) => {
    let _TempList_Final = []
    if(listType===0) _TempList_Final = JSON.parse(JSON.stringify(G_DPMList_Final));
    if(listType===1) _TempList_Final = JSON.parse(JSON.stringify(G_CHWPList_Final));
    if(listType===2) _TempList_Final = JSON.parse(JSON.stringify(G_CDWPList_Final));
    if(listType===3) _TempList_Final = JSON.parse(JSON.stringify(G_BTUList_Final));

    // Ensure the index is within the valid range for shifting up
    if (index > 0 && index < _TempList_Final.length) {
      // Swap the element at the given index with the element before it
      let temp = _TempList_Final[index];
      _TempList_Final[index] = _TempList_Final[index - 1];
      _TempList_Final[index - 1] = temp;
    }else return

    if(listType===0) setG_DPMList_Final(_TempList_Final)
    if(listType===1) setG_CHWPList_Final(_TempList_Final)
    if(listType===2) setG_CDWPList_Final(_TempList_Final)
    if(listType===3) setG_BTUList_Final(_TempList_Final)
    
  };
  const F_ShiftDn_FinalList = (index, listType) => {
    let _TempList_Final = []
    if(listType===0) _TempList_Final = JSON.parse(JSON.stringify(G_DPMList_Final));
    if(listType===1) _TempList_Final = JSON.parse(JSON.stringify(G_CHWPList_Final));
    if(listType===2) _TempList_Final = JSON.parse(JSON.stringify(G_CDWPList_Final));
    if(listType===3) _TempList_Final = JSON.parse(JSON.stringify(G_BTUList_Final));

    // Ensure the index is within the valid range for shifting up
    if (index >= 0 && index < _TempList_Final.length-1) {
      // Swap the element at the given index with the element before it
      let temp = _TempList_Final[index];
      _TempList_Final[index] = _TempList_Final[index + 1];
      _TempList_Final[index + 1] = temp;
    }else return
    
    if(listType===0) setG_DPMList_Final(_TempList_Final)
    if(listType===1) setG_CHWPList_Final(_TempList_Final)
    if(listType===2) setG_CDWPList_Final(_TempList_Final)
    if(listType===3) setG_BTUList_Final(_TempList_Final)
  };

  const F_ShiftDel_FinalList = (index, listType) => {
    let _TempList_Final = []
    if(listType===0) _TempList_Final = JSON.parse(JSON.stringify(G_DPMList_Final));
    if(listType===1) _TempList_Final = JSON.parse(JSON.stringify(G_CHWPList_Final));
    if(listType===2) _TempList_Final = JSON.parse(JSON.stringify(G_CDWPList_Final));
    if(listType===3) _TempList_Final = JSON.parse(JSON.stringify(G_BTUList_Final));

    if (index >= 0 && index < _TempList_Final.length) {
      _TempList_Final.splice(index, 1);
    }else return
    
    if(listType===0) {
      setG_DPMList_Final(_TempList_Final)
      F_GetChPairCount(G_BTUList_Final, _TempList_Final)
      
    }
    if(listType===1) setG_CHWPList_Final(_TempList_Final)
    if(listType===2) setG_CDWPList_Final(_TempList_Final)
    if(listType===3) {
      setG_BTUList_Final(_TempList_Final)
      F_GetChPairCount(_TempList_Final, G_DPMList_Final)
      
    }
  };

  const F_CallSel=(nPg)=>{
    setG_nSelectionPg(nPg)
  }

  const F_ChooSeBd=async(bf_info)=>{
    // console.log("bf_info", bf_info);   // to check BD_ID
    setG_BdObj_Sel(bf_info)
    await F_LoadDevsInBd(user_id, bf_info._id);
    setG_BdIDSelected(bf_info._id)
    /** clear sensor list */
    setG_DPMList_Final([])
    setG_BTUList_Final([])
    setG_CHWPList_Final([])
    setG_CDWPList_Final([])
    F_CallSel(0)
  }

  const F_Toggle_CfgSel=(nIdx)=>{
    if (nIdx===1){
      setG_Cfg_Sel_TrInvert(!G_Cfg_Sel_TrInvert)
    }else if (nIdx===2){
      if(G_Cfg_Sel_TrHiPass) {    // when user uncheck Hi Pass selection
        setG_Cfg_Sel_TrHiPassValue("")  // clear Hi Pass value
      }
      setG_Cfg_Sel_TrHiPass(!G_Cfg_Sel_TrHiPass)
    }else if (nIdx===3){
      if(G_Cfg_Sel_TrLoPass){   // when user uncheck Lo Pass selection
        setG_Cfg_Sel_TrLoPassValue("")  // clear Lo pass value
      }
      setG_Cfg_Sel_TrLoPass(!G_Cfg_Sel_TrLoPass)
    }
  }
  
  const F_SetCfgValue = (event, nIdx) => {
    const selectedValue = event.target.value;
    if (nIdx===2){
      setG_Cfg_Sel_TrHiPassValue(selectedValue);
    }else if (nIdx===3){
      setG_Cfg_Sel_TrLoPassValue(selectedValue);
    }
  };

  const F_ToggleGraph=(nIdx, nFunc)=>{
    let _GraphSetting
    if (nIdx===1) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Effi));
    else if (nIdx===2)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_CDWP));
    else if (nIdx===3)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Tr_Total));

    else if (nIdx===4)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Chillers));
    else if (nIdx===5)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Chwp));
    else if (nIdx===6)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Cdwp));
    else if (nIdx===7)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Ch_Total));
    else if (nIdx===8)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Chwp_Total));
    else if (nIdx===9)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Tr_EachCh));
    else if (nIdx===10)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Effi_EachCh));
    

    if (nFunc===1){   
      _GraphSetting.sel=!_GraphSetting.sel;          
      if(!_GraphSetting.sel){  // user uncheck Main Setting. reset to default
        _GraphSetting = G_Graph_Init;
      }
    }else if (nFunc===2){
      _GraphSetting.hiSel=!_GraphSetting.hiSel;
      if (!_GraphSetting.hiSel){
        _GraphSetting.hi=""
      }
    }else if (nFunc===3){
      _GraphSetting.loSel=!_GraphSetting.loSel;
      if(!_GraphSetting.loSel){
        _GraphSetting.lo=""
      }
    }
    
    if (nIdx===1) setG_Cfg_Graph_Effi(_GraphSetting)
    else if (nIdx===2)  setG_Cfg_Graph_kW_CDWP(_GraphSetting)
    else if (nIdx===3)  setG_Cfg_Graph_Tr_Total(_GraphSetting)

    else if (nIdx===4)  setG_Cfg_Graph_kW_Chillers(_GraphSetting)
    else if (nIdx===5)  setG_Cfg_Graph_kW_Chwp(_GraphSetting)
    else if (nIdx===6)  setG_Cfg_Graph_kW_Cdwp(_GraphSetting)
    else if (nIdx===7)  setG_Cfg_Graph_kW_Ch_Total(_GraphSetting)
    else if (nIdx===8)  setG_Cfg_Graph_kW_Chwp_Total(_GraphSetting)
    else if (nIdx===9)  setG_Cfg_Graph_Tr_EachCh(_GraphSetting)
    else if (nIdx===10)  setG_Cfg_Graph_Effi_EachCh(_GraphSetting)
        
  }

  const F_SetGraphValue=(event, nIdx, nFunc)=>{
    const selectedValue = event.target.value;
    let _GraphSetting = G_Graph_Init
    if(nIdx===1)  _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Effi));
    else if(nIdx===2) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_CDWP));
    else if(nIdx===3) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Tr_Total));

    else if(nIdx===4) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Chillers));
    else if(nIdx===5) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Chwp));
    else if(nIdx===6) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Cdwp));
    else if(nIdx===7) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Ch_Total));
    else if(nIdx===8) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_kW_Chwp_Total));
    else if(nIdx===9) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Tr_EachCh));
    else if(nIdx===10) _GraphSetting = JSON.parse(JSON.stringify(G_Cfg_Graph_Effi_EachCh));
    

    if(nFunc===1){    // set Hi Limit
      _GraphSetting.hi = selectedValue
    }else if (nFunc===2){   // set lo limit
      _GraphSetting.lo = selectedValue
    }

    if(nIdx===1)  setG_Cfg_Graph_Effi(_GraphSetting)
    else if(nIdx===2) setG_Cfg_Graph_kW_CDWP(_GraphSetting)
    else if(nIdx===3) setG_Cfg_Graph_Tr_Total(_GraphSetting)

    else if(nIdx===4) setG_Cfg_Graph_kW_Chillers(_GraphSetting)
    else if(nIdx===5) setG_Cfg_Graph_kW_Chwp(_GraphSetting)
    else if(nIdx===6) setG_Cfg_Graph_kW_Cdwp(_GraphSetting)
    else if(nIdx===7) setG_Cfg_Graph_kW_Ch_Total(_GraphSetting)
    else if(nIdx===8) setG_Cfg_Graph_kW_Chwp_Total(_GraphSetting)
    else if(nIdx===9) setG_Cfg_Graph_Tr_EachCh(_GraphSetting)
    else if(nIdx===10) setG_Cfg_Graph_Effi_EachCh(_GraphSetting)
      
  }

  const F_ToggleScatter=(idx, func)=>{
    
    let _Cfg_Scatter_CHs = JSON.parse(JSON.stringify(G_Cfg_Scatter_CHs));

    if (func===1) _Cfg_Scatter_CHs[idx].sel = !_Cfg_Scatter_CHs[idx].sel
    else if (func===2) {
      _Cfg_Scatter_CHs[idx].maxRal.sel = !_Cfg_Scatter_CHs[idx].maxRal.sel
      if(!_Cfg_Scatter_CHs[idx].maxRal.sel) _Cfg_Scatter_CHs[idx].maxRal.value = ""
    }
    else if (func===3) {
      _Cfg_Scatter_CHs[idx].y_max.sel = !_Cfg_Scatter_CHs[idx].y_max.sel
      if(!_Cfg_Scatter_CHs[idx].y_max.sel) _Cfg_Scatter_CHs[idx].y_max.value = ""
    }
    else if (func===4) {
      _Cfg_Scatter_CHs[idx].y_min.sel = !_Cfg_Scatter_CHs[idx].y_min.sel
      if(!_Cfg_Scatter_CHs[idx].y_min.sel) _Cfg_Scatter_CHs[idx].y_min.value = ""
    }
    else if (func===5) {
      _Cfg_Scatter_CHs[idx].x_max.sel = !_Cfg_Scatter_CHs[idx].x_max.sel
      if(!_Cfg_Scatter_CHs[idx].x_max.sel) _Cfg_Scatter_CHs[idx].x_max.value = ""
    }
    else if (func===6) {
      _Cfg_Scatter_CHs[idx].x_min.sel = !_Cfg_Scatter_CHs[idx].x_min.sel
      if(!_Cfg_Scatter_CHs[idx].x_min.sel) _Cfg_Scatter_CHs[idx].x_min.value = ""
    }

    setG_Cfg_Scatter_CHs(_Cfg_Scatter_CHs)

  }

  const F_SetScatterValue=(event, idx, func)=>{
    const selectedValue = parseFloat(event.target.value);
    
    let _Cfg_Scatter_CHs = JSON.parse(JSON.stringify(G_Cfg_Scatter_CHs));
    if (func===2) _Cfg_Scatter_CHs[idx].maxRal.value = selectedValue
    else if (func===3) _Cfg_Scatter_CHs[idx].y_max.value = selectedValue
    else if (func===4) _Cfg_Scatter_CHs[idx].y_min.value = selectedValue
    else if (func===5) _Cfg_Scatter_CHs[idx].x_max.value = selectedValue
    else if (func===6) _Cfg_Scatter_CHs[idx].x_min.value = selectedValue

    setG_Cfg_Scatter_CHs(_Cfg_Scatter_CHs)
    
  }

  const F_ValidateGraph_Single=(graphSetting, graphName)=>{
    try {
      let _graphSetting = JSON.parse(JSON.stringify(graphSetting));
      if(_graphSetting?.hiSel && _graphSetting?.hi.trim()===""){
        toast(`Graph Setting (${graphName}): Invalid Hi Limit`)
        return {validPass:false}
      }
      if(_graphSetting?.loSel && _graphSetting?.lo.trim()===""){
        toast(`Graph Setting (${graphName}): Invalid Lo Limit`)
        return {validPass:false}
      }
      if((_graphSetting?.loSel && 
        _graphSetting?.hiSel) && 
        parseFloat(_graphSetting?.lo) >= parseFloat(_graphSetting?.hi)
      ){
        toast(`Graph Setting (${graphName}): Invalid Hi / Lo Limit`)
        return {validPass:false}
      }
  
      /** generate cfgValue */
      let graphCfg={}
      if(graphSetting?.sel && graphSetting?.hiSel){
        graphCfg.hiLimit = parseFloat(graphSetting.hi)
      }

      if(graphSetting?.sel && graphSetting?.loSel){
        graphCfg.loLimit = parseFloat(graphSetting.lo)
      }


      return {validPass:true, graphCfg}
      
    } catch (error) {
      toast(`F_ValidateGraph_Single Exception Err: ${error.message}`)
      return {validPass:false}
    }
  }

  const F_ValScatter_Single=(stScatter, setIdx)=>{
    let sErrName = `${G_ChScatterPrefixName} ${setIdx+1}`
    if(stScatter.maxRal.sel && isNaN(stScatter.maxRal.value)) {
      toast(`${sErrName}, Max RLA error`)
      return false
    }
    if(stScatter.y_max.sel && isNaN(stScatter.y_max.value)) {
      toast(`${sErrName}, Y Max error`)
      return false
    }
    if(stScatter.y_min.sel && isNaN(stScatter.y_min.value)) {
      toast(`${sErrName}, Y Min error`)
      return false
    }
    if(stScatter.x_max.sel && isNaN(stScatter.x_max.value)) {
      toast(`${sErrName}, X Max error`)
      return false
    }
    if(stScatter.x_min.sel && isNaN(stScatter.x_min.value)) {
      toast(`${sErrName}, X Min error`)
      return false
    }
    if(stScatter.y_min.sel && stScatter.y_max.sel && stScatter.y_min.value >= stScatter.y_max.value){
      toast(`${sErrName}, Y, Invalid Min Max range`)
      return false
    }
    if(stScatter.x_min.sel && stScatter.x_max.sel && stScatter.x_min.value >= stScatter.x_max.value){
      toast(`${sErrName}, X, Invalid Min Max range`)      
      return false
    }
    return true
  }
  const F_ValidateScatterPara=()=>{
    for (let i = 0; i < G_Cfg_Scatter_CHs.length; i++) {
      let validPass = F_ValScatter_Single(G_Cfg_Scatter_CHs[i], i)
      if(!validPass) return {validPass: false}
    }
    return {validPass: true, scatterInfo:G_Cfg_Scatter_CHs}
  }
  const F_ValidateConfigPara=()=>{
    try {      
      let cfgPara={}
      cfgPara.tr_invert = G_Cfg_Sel_TrInvert;   /** insert invert */

      if (G_Cfg_Sel_TrHiPass && G_Cfg_Sel_TrHiPassValue.trim()===""){
        toast("Hi Pass Error")
        return {validPass: false}
      }
      if(G_Cfg_Sel_TrHiPass){
        cfgPara.tr_hiPass = parseFloat(G_Cfg_Sel_TrHiPassValue);   /** insert invert */
      }
  
      if (G_Cfg_Sel_TrLoPass && G_Cfg_Sel_TrLoPassValue.trim()===""){      
        toast("Lo Pass Error")
        return {validPass: false}
      }
      if(G_Cfg_Sel_TrLoPass){
        cfgPara.tr_loPass = parseFloat(G_Cfg_Sel_TrLoPassValue);   /** insert invert */
      }
  
      if ((G_Cfg_Sel_TrHiPass && G_Cfg_Sel_TrLoPass) && parseFloat(G_Cfg_Sel_TrLoPassValue) >= parseFloat(G_Cfg_Sel_TrHiPassValue)) {
        toast("Invalid Hi / Lo Pass Filter value")
        return {validPass: false}
      } 

      /** validate graph setting - Efficiency */
      let valEffi = F_ValidateGraph_Single(G_Cfg_Graph_Effi, ReactName_Effi)
      if(!valEffi.validPass) return {validPass: false}
      cfgPara.graph_Effi = valEffi?.graphCfg;

      let valkW_Cdwp = F_ValidateGraph_Single(G_Cfg_Graph_kW_CDWP, ReactName_kw_Cdwp_Total)
      if(!valkW_Cdwp.validPass) return {validPass: false}
      cfgPara.graph_kw_Cdwp_Total = valkW_Cdwp?.graphCfg;
      
      let val_Common = F_ValidateGraph_Single(G_Cfg_Graph_Tr_Total, ReactName_TR_Total)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_TR_Total = val_Common?.graphCfg;

      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_kW_Chillers, ReactName_kW_Chillers)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_kW_Chillers = val_Common?.graphCfg;

      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_kW_Chwp, ReactName_kW_Chwp)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_kW_Chwp = val_Common?.graphCfg;

      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_kW_Cdwp, ReactName_kW_Cdwp)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_kW_Cdwp = val_Common?.graphCfg;

      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_kW_Ch_Total, ReactName_kW_Ch_Total)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_kW_Ch_Total = val_Common?.graphCfg;

      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_kW_Chwp_Total, ReactName_kW_Chwp_Total)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_kW_Chwp_Total = val_Common?.graphCfg;
      
      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_Tr_EachCh, ReactName_Tr_EachCh)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_Tr_EachCh = val_Common?.graphCfg;
  
      val_Common = F_ValidateGraph_Single(G_Cfg_Graph_Effi_EachCh, ReactName_Effi_EachCh)
      if(!val_Common.validPass) return {validPass: false}
      cfgPara.graph_Effi_EachCh = val_Common?.graphCfg;
  
      return {validPass: true, cfgPara}
    } catch (error) {
      toast("Configuration Exception Error", error.message)
      toast(`Configuration Exception Error, ${error.message}`)
      return {validPass: false}
    }
  }
  

  return (
    <div className="" >
      {G_nSelectionPg===1 && <TpSelection 
        titleName={'Building'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_ChooSeBd}
        selList={G_BdList}/>}
      {G_nSelectionPg===2 && <TpSelection 
        titleName={'Chiller DPM'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_AddDpm}
        selType={"multi"}
        selList={G_DPMList}/>}
      {G_nSelectionPg===3 && <TpSelection 
        titleName={'CHWP DPM'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_AddChwp}
        selType={"multi"}
        selList={G_DPMList}/>}
      {G_nSelectionPg===4 && <TpSelection 
        titleName={'CDWP DPM'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_AddCdwp}
        selType={"multi"}
        selList={G_DPMList}/>}
      {G_nSelectionPg===5 && <TpSelection 
        titleName={'CH BTU'}
        onClickCancel={()=>F_CallSel(0)} 
        disName = {'name'}
        onClickSelItem = {F_AddBtu}
        selType={"multi"}
        selList={G_BTUList}/>}
      <div className="Summer_ModuleSubItemContainer">
        
        <div className="sortHor Summer_InfoKeyInSlot">
          <div className="Summer_Info_Title">Name :</div>
          <div className="Summer_Info_Value">
            <input className="Summer_info_input" placeholder="< Insert Name>" onChange={F_SetModuleName} />
          </div>
        </div>

        <div className="sortHor Summer_InfoKeyInSlot">
          <div className="Summer_Info_Title">Building:</div>
          <div className="Summer_Info_Value hoverPointer"
          onClick={()=>F_CallSel(1)}>
            {
              G_BdObj_Sel?.name?
              <div className="textColor_Input">{G_BdObj_Sel?.name}</div>:
              <div className="Summer_PlaceHolderColor">{`< Choose Building >`}</div>
            }
          </div>
        </div>

        <div className="sortHor Summer_InfoKeyInSlot">
          <div className="Summer_Info_Title">Start:</div>
          <div>
            <input
              className="Summer_TimeInput"
              type="datetime-local"
              onChange={F_SetStartTime}
            />
          </div>
        </div>
        <div className="sortHor Summer_InfoKeyInSlot">
          <div className="Summer_Info_Title">End:</div>
          <div>
            <input
              className="Summer_TimeInput"
              type="datetime-local"
              onChange={F_SetEndTime}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="Summer_ModuleSubItemContainer">
          <div className="">Chiller DPM</div>
          {G_DPMList_Final.map((item, ind) => (
            <div key={`key_chiDpm_${ind}`} className="Summer_DaReq_DevList sortHor">
              <div>{`${ind+1}.`}</div>
              <div className="Summer_DaReq_Item_Name">{item.name}</div>            
              <div className="stayRight sortHor"> 
                <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                onClick={()=>F_ShiftUp_FinalList(ind, 0)}><IoArrowUp/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                onClick={()=>F_ShiftDn_FinalList(ind, 0)}><IoArrowDown/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                onClick={()=>F_ShiftDel_FinalList(ind, 0)}><RiDeleteBinLine/></div>                
              </div>  
            </div>
          ))}
          <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_CallSel(2)}>Add +</div>           
        </div>

        <div className="Summer_ModuleSubItemContainer">
          <div className="">CHWP DPM</div>
          {G_CHWPList_Final.map((item, ind) => (
            <div key={`key_chwpDpm_${ind}`} className="Summer_DaReq_DevList sortHor">
              <div>{`${ind+1}.`}</div>
              <div className="Summer_DaReq_Item_Name">{item.name}</div>            
              <div className="stayRight sortHor"> 
                <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                onClick={()=>F_ShiftUp_FinalList(ind, 1)}><IoArrowUp/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                onClick={()=>F_ShiftDn_FinalList(ind, 1)}><IoArrowDown/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                onClick={()=>F_ShiftDel_FinalList(ind, 1)}><RiDeleteBinLine/></div>                
              </div>  
            </div>
          ))}
          <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_CallSel(3)}>Add +</div>           
        </div>

        <div className="Summer_ModuleSubItemContainer">
          <div className="">CDWP DPM</div>
          {G_CDWPList_Final.map((item, ind) => (
            <div key={`key_chwpDpm_${ind}`} className="Summer_DaReq_DevList sortHor">
              <div>{`${ind+1}.`}</div>
              <div className="Summer_DaReq_Item_Name">{item.name}</div>            
              <div className="stayRight sortHor"> 
                <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                onClick={()=>F_ShiftUp_FinalList(ind, 2)}><IoArrowUp/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                onClick={()=>F_ShiftDn_FinalList(ind, 2)}><IoArrowDown/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                onClick={()=>F_ShiftDel_FinalList(ind, 2)}><RiDeleteBinLine/></div>                
              </div>  
            </div>
          ))}
          <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_CallSel(4)}>Add +</div>           
        </div>
        
        <div className="Summer_ModuleSubItemContainer">
          <div className="">CH BTU</div>
          {G_BTUList_Final.map((item, ind) => (
            <div key={`key_chwpDpm_${ind}`} className="Summer_DaReq_DevList sortHor">
              <div>{`${ind+1}.`}</div>
              <div className="Summer_DaReq_Item_Name">{item.name}</div>            
              <div className="stayRight sortHor"> 
                <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                onClick={()=>F_ShiftUp_FinalList(ind, 3)}><IoArrowUp/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                onClick={()=>F_ShiftDn_FinalList(ind, 3)}><IoArrowDown/></div>
                <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                onClick={()=>F_ShiftDel_FinalList(ind, 3)}><RiDeleteBinLine/></div>                
              </div>  
            </div>
          ))}
          <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_CallSel(5)}>Add +</div>           
        </div>

      </div>

      <div className="Summer_ModuleSubItemContainer">

        <div style={{marginBottom:"10px", fontWeight:"500"}}>
          <div className="">
            Configuration
          </div>
        </div>

        <div>
          <div className="Summer_ModuleCfg_Title">
            Cooling Capacity
          </div>
          <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_Toggle_CfgSel(1)}>{G_Cfg_Sel_TrInvert?<FaCheck/>:""}</div>
            <div>Invert value</div>
          </div>
          <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_Toggle_CfgSel(2)}>{G_Cfg_Sel_TrHiPass?<FaCheck/>:""}</div>
            <div>Hi Pass Filter (For each BTU)</div>
            {G_Cfg_Sel_TrHiPass?<input type="number" onChange={(e)=>F_SetCfgValue(e, 2)} className="stayRight Summer_info_input" placeholder="<Insert value>"/>: <div></div>}            
          </div>
          <div className="sortHor Summer_ModuleCfg_Item">
            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_Toggle_CfgSel(3)}>{G_Cfg_Sel_TrLoPass?<FaCheck/>:""}</div>
            <div>Lo Pass Filter (For each BTU)</div>
            {G_Cfg_Sel_TrLoPass?<input type="number"  onChange={(e)=>F_SetCfgValue(e, 3)} className="stayRight Summer_info_input" placeholder="<Insert value>"/>: <div></div>}   
          </div>
        </div>

        <div>
          <div className="Summer_ModuleCfg_Title">
            Trending Graph Setting
          </div>


          <TpGraphConfig graphName = {ReactName_Tr_EachCh}
            curConfig = {G_Cfg_Graph_Tr_EachCh}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={9}/>
          <TpGraphConfig graphName = {ReactName_TR_Total}
            curConfig = {G_Cfg_Graph_Tr_Total}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={3}/>
          
          <TpGraphConfig graphName = {ReactName_kW_Chillers}
            curConfig = {G_Cfg_Graph_kW_Chillers}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={4}/>
          <TpGraphConfig graphName = {ReactName_kW_Chwp}
            curConfig = {G_Cfg_Graph_kW_Chwp}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={5}/>
          <TpGraphConfig graphName = {ReactName_kW_Cdwp}
            curConfig = {G_Cfg_Graph_kW_Cdwp}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={6}/>
          <TpGraphConfig graphName = {ReactName_kW_Ch_Total}
            curConfig = {G_Cfg_Graph_kW_Ch_Total}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={7}/>
          <TpGraphConfig graphName = {ReactName_kW_Chwp_Total}
            curConfig = {G_Cfg_Graph_kW_Chwp_Total}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={8}/>
          
          <div>
            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(2, 1)}>{G_Cfg_Graph_kW_CDWP?.sel?<FaCheck/>:<div/>}</div>
              <div>{ReactName_kw_Cdwp_Total}</div>
            </div>
            {G_Cfg_Graph_kW_CDWP?.sel?<div>
              <div className="sortHor Summer_ModuleCfg_Item">
                <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(2, 2)}>{G_Cfg_Graph_kW_CDWP?.hiSel?<FaCheck/>:<div/>}</div>
                <div>Hi Limit</div>
                {G_Cfg_Graph_kW_CDWP?.hiSel?<input type="number" onChange={(e)=>F_SetGraphValue(e, 2, 1)} className="stayRight Summer_info_input" placeholder="<Insert value>"/>:<div/>}
              </div>
              <div className="sortHor Summer_ModuleCfg_Item">
                <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(2, 3)}>{G_Cfg_Graph_kW_CDWP?.loSel?<FaCheck/>:<div/>}</div>
                <div>Lo Limit</div>
                {G_Cfg_Graph_kW_CDWP?.loSel?<input type="number" onChange={(e)=>F_SetGraphValue(e, 2, 2)} className="Summer_info_input stayRight" placeholder="<Insert value>"/>:<div/>}
              </div>
            </div>:<div/>}
          </div>

          <div>
            <div className="sortHor Summer_ModuleCfg_Item">
              <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(1, 1)}>{G_Cfg_Graph_Effi?.sel?<FaCheck/>:<div/>}</div>
              <div>{ReactName_Effi}</div>
            </div>
            {G_Cfg_Graph_Effi?.sel?<div>
              <div className="sortHor Summer_ModuleCfg_Item">
                <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(1, 2)}>{G_Cfg_Graph_Effi?.hiSel?<FaCheck/>:<div/>}</div>
                <div>Hi Limit</div>
                {G_Cfg_Graph_Effi?.hiSel?<input type="number" onChange={(e)=>F_SetGraphValue(e, 1, 1)} className="stayRight Summer_info_input" placeholder="<Insert value>"/>:<div/>}
              </div>
              <div className="sortHor Summer_ModuleCfg_Item">
                <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" onClick={()=>F_ToggleGraph(1, 3)}>{G_Cfg_Graph_Effi?.loSel?<FaCheck/>:<div/>}</div>
                <div>Lo Limit</div>
                {G_Cfg_Graph_Effi?.loSel?<input type="number" onChange={(e)=>F_SetGraphValue(e, 1, 2)} className="Summer_info_input stayRight" placeholder="<Insert value>"/>:<div/>}
              </div>
            </div>:<div/>}
          </div>

          <TpGraphConfig graphName = {ReactName_Effi_EachCh}
            curConfig = {G_Cfg_Graph_Effi_EachCh}
            fToggleGraph={F_ToggleGraph}
            fSetGraphValue={F_SetGraphValue}
            nGraphId={10}/>
        </div>

        <div>          
          <div className="Summer_ModuleCfg_Title">
            Chiller Effi. Scatter Plot
          </div>
          {G_Cfg_Scatter_CHs.map((c, ind) => (
            <div key={`ChEffiScatter_${ind}`} >
              <TpScatterCfg 
              graphName={`${G_ChScatterPrefixName} ${ind + 1}`}
              curConfig = {c}
              fToggle = {F_ToggleScatter}
              fSetValue = {F_SetScatterValue}
              setIdx = {ind}
              />
            </div>
          ))}
        </div>

      </div>

      <div className="Summer_DaReq_SendButton hoverPointer" onClick={F_SendToAnalysis}>
        Send
      </div>
    </div>
  );
};

export default MdChEffi0001;
