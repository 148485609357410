import React, { useEffect, useState } from "react";
import MdChEffi0001 from "./ModuleTemplate/MD_0001_ChEffi/Module_0001_ChEffi";
// import BD_Dev_Fn from "../../../function/MainServer/BdAndDevices.jsx/BdAndDeviceFn";
// import moment from 'moment-timezone';
import TpPgHeader from "../Common/TpPgHeader";
import { useNavigate } from "react-router-dom";
import TpSelection from "../Common/TpSelection";
// import { IoMdArrowDropdown } from "react-icons/io";

const AnalysticModulePage = () => {
  // const [options, setOptions] = useState([
  //   { value: "option1", label: "Option 1" },
  //   { value: "option2", label: "Option 2" },
  //   { value: "option3", label: "Option 3" },
  // ]);

  const navigate = useNavigate();

  const [G_DiaPage, setG_DiaPage] = useState(0);

  useEffect(() => {
    async function startUp() {
      // let relatedBd = await BD_Dev_Fn.F_GetRelatedBd_user_id(1)
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
    };
    // eslint-disable-next-line
  }, []);

  const F_ToPage = (pgIdx) => {
    if (pgIdx === 0) {
      navigate("/module/md/selection");
    }
  };

  const F_SetDiaPage = (nPage) => {
    setG_DiaPage(nPage);
  };

  return (
    <div className="Summer_EachPg ">
      <TpPgHeader headerTitle={"Chiller Plantroom Efficiency"} onClick_Back={() => F_ToPage(0)} />
      {G_DiaPage === 1 && <TpSelection onClickCancel={() => F_SetDiaPage(0)} />}
      <div className="Summer_PageLayout">
        {/* <div className="sortHor Summer_SelModuleContainer hoverPointer"> */}
        {/* <div className="sortHor Summer_SelModuleContainer hoverPointer">
          <div className="Summer_SelModule_Name">
            Chiller Plantroom Efficiency
          </div>
          <div className="Summer_SelModule_Icon bothCenter">
            <IoMdArrowDropdown />
          </div>
        </div> */}

        <div className="Summer_ModuleContainer">
          <MdChEffi0001 />
        </div>
      </div>
    </div>
  );
};

export default AnalysticModulePage;
