




export function F_ExtractFromObj(object, arr_name) {
  let newObj = {};
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      if (arr_name.includes(key)) {
        newObj[key] = object[key];
      }
    }
  }
  return newObj;
}

export function F_SetNestedProperty(objLevel, arr_levels, value) {

  let ClonObj = objLevel;  // Work directly on objLevel
  // Iterate through each level except the last one
  for (let i = 0; i < arr_levels.length - 1; i++) {
      const curLevel = arr_levels[i];    
      // If the level doesn't exist, create it as an empty object
      if (!ClonObj[curLevel]) {
          ClonObj[curLevel] = {};
      }    
      // Move deeper into the object
      ClonObj = ClonObj[curLevel];
  }
  // Set the value at the final level
  ClonObj[arr_levels[arr_levels.length - 1]] = value;
  return objLevel; 
}