import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import TpPgHeader from "../../../Common/TpPgHeader";
import TpChooseSensorWithBd from "../../../Common/TpChooseSensorWithBd";
import TpSelection from "../../../Common/TpSelection";
import { F_RotatePivotInterval, F_RotateStatisticalMeasure, F_AllTimeZone, F_GetLineDisplayOption, F_Rotate_LineDisplayOption, F_GetLineDisplayOption_byIdx } from "../CommonFn/ModuleCommonFn";
import { F_ExtractFromObj, F_SetNestedProperty } from "../../../../../function/Utilities/ObjectFn";
import { F_Arr_ShiftDel, F_Arr_ShiftDn, F_Arr_ShiftUp } from "../../../../../function/Utilities/ArrayFn";
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
// import daReqFn_R02 from "../../../../../function/DaServer/DataAnalysisQuery/DaReq_Fn";
import daReqFn from '../../../../../function/DaServer/DataAnalysisQuery/DaReq_Fn';
import { toast } from 'react-toastify';
import { F_GetUserId } from "../../../../../function/UserManagement/UserFn";
import TpToggleButton from '../../../Common/TpToggleButton';
// import TpTimeRangeAndValue from "../../../Common/TpTimeRangeAndValue";
import TpDatetimeRangeAndValue from "../../../Common/TpDatetimeRangeAndValue";
import { TbPointFilled } from "react-icons/tb";
import TpColorPicker from '../../../Common/TpColorPicker';

function Module_0003_LineTrend() {
    const G_ModuleID = 3;
    const G_sumUpName = "sumUpName";

    const GC_Init_xCL={selected: false, startTime:"", endTime:"", value:null, repeatDaily: false}
    const defaultLineColor = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

    const [G_sModuleName, setG_sModuleName] = useState("");
    const [G_sStartDate, setG_sStartDate] = useState("");
    const [G_sEndDate, setG_sEndDate] = useState("");
    const [G_nSelectionPg, setG_nSelectionPg] = useState(0);

    const [G_GraphParaList, setG_GraphParaList] = useState([]);

    const [G_sPivotInterval, setG_sPivotInterval] = useState("10min");
    const [G_sStatMeasure, setG_sStatMeasure] = useState("mean");
    const [G_bMultiLanguage, setG_bMultiLanguage] = useState(false);

    const [G_sTimezone, setG_sTimezone] = useState("");

    const [G_GroupByWeek, setG_GroupByWeek] = useState(true);
    const [G_ModuleCfg, setG_ModuleCfg] = useState({HighLight_List:[GC_Init_xCL]});

    // const [G_LineDisplayOption, setG_LineDisplayOption] = useState("");
    
    const [G_cpIdx, setG_cpIdx] = useState();
    const [G_colorPicked, setG_colorPicked] = useState([]);

    const [G_sendDisabled, setG_sendDisabled] = useState(false);

    const navigate = useNavigate();

    const F_ToPage = (pgIdx) => {
        if (pgIdx === 0) {
            navigate("/module/main");
        }
    };

    

    const F_SetName_Date=(event, fn)=>{
        const sVal = event.target.value
        if(fn===1)  setG_sModuleName(sVal)
        else if (fn===2)  setG_sStartDate(sVal)
        else if (fn===3)  setG_sEndDate(sVal)
        else if (fn===4) {
            let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));            
            _ModuleCfg = F_SetNestedProperty(_ModuleCfg, ["GraphOption", "yLabel"], sVal)
            setG_ModuleCfg(_ModuleCfg)
        }
        else if (fn===5) {
            let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));            
            _ModuleCfg = F_SetNestedProperty(_ModuleCfg, ["GraphOption", "title"], sVal)
            setG_ModuleCfg(_ModuleCfg)
        }
        else if (fn===6) {
            let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));            
            _ModuleCfg = F_SetNestedProperty(_ModuleCfg, ["GraphOption", G_sumUpName], sVal)
            setG_ModuleCfg(_ModuleCfg)
        }
    }

    const F_SetDiaPage=(nPg, idx)=>{
        setG_nSelectionPg(nPg)
        if(nPg === 4) setG_cpIdx(idx);
    }

    const F_RotInterval=()=>{
        let newInterval = F_RotatePivotInterval(G_sPivotInterval)
        setG_sPivotInterval(newInterval)
    }

    const F_RotLineOption=()=>{        
        let newValue = F_Rotate_LineDisplayOption(G_ModuleCfg?.GraphOption?.LineDisplayOption)        
        let sumName = F_GetLineDisplayOption_byIdx(1)

        let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));            
        if (newValue!== sumName){
            /** delete the graph option */
            delete _ModuleCfg?.GraphOption?.sumUpName
            
        }

        _ModuleCfg = F_SetNestedProperty(_ModuleCfg, ["GraphOption","LineDisplayOption"], newValue)
        setG_ModuleCfg(_ModuleCfg)  // ???
        // setG_LineDisplayOption(newValue)
    }

    const F_RotStatMeasure=()=>{
        let newStatMeasure = F_RotateStatisticalMeasure(G_sStatMeasure)
        setG_sStatMeasure(newStatMeasure)
    }

    const F_ChooseTz=(info)=>{
        if (info?.name) setG_sTimezone(info.name)
        F_SetDiaPage(0);  /** Close the selection dialog */
    }

    const F_ShiftUp_FinalList=(idx)=>{
        let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
        _GraphParaList = F_Arr_ShiftUp(_GraphParaList, idx)
        setG_GraphParaList(_GraphParaList)
    }

    const F_ShiftDn_FinalList=(idx)=>{
        let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
        _GraphParaList = F_Arr_ShiftDn(_GraphParaList, idx)
        setG_GraphParaList(_GraphParaList)
    }

    const F_ShiftDel_FinalList=(idx)=>{
        let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
        _GraphParaList = F_Arr_ShiftDel(_GraphParaList, idx)
        setG_GraphParaList(_GraphParaList)
        // if(_GraphParaList.length === 0) setG_yAxisName("");
    }

    const F_AddPara=(paraInfo)=>{
        let paraInfo_Extract = {
          bdInfo:F_ExtractFromObj(paraInfo?.bdInfo, ["_id", "name"]), 
          sensorInfo:F_ExtractFromObj(paraInfo?.sensorInfo, ["_id", "name", "type", "devID", "buidling_id"]),
          paraInfo:F_ExtractFromObj(paraInfo?.paraInfo, ["_id", "dataName", "dataUnit", "sensorType", "dataType","dataIndex"])
        }
        
        let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList))
        _GraphParaList.push(paraInfo_Extract)
        setG_GraphParaList(_GraphParaList)
        F_addColorPicker();
        // if(G_yAxisName === "") setG_yAxisName(paraInfo_Extract.paraInfo.dataName);
        F_SetDiaPage(0);  /** Close the selection dialog */
    }

    const F_Toggle = (Action) => {
        if (Action === "GroupByWeek") {
          setG_GroupByWeek(!G_GroupByWeek);
        }else if (Action === "MultiLanguage"){
          setG_bMultiLanguage(!G_bMultiLanguage);
        }else if (Action==="Toggle_CL"){
          let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
          _ModuleCfg.bCL = !_ModuleCfg?.bCL
          
          /** set CL_ListAlso */
          if(_ModuleCfg.bCL===true)  _ModuleCfg.CL_List = [GC_Init_xCL]
          else {
            _ModuleCfg.CL_List = []
            delete _ModuleCfg.CL_Value;
          }
    
          setG_ModuleCfg(_ModuleCfg)
        }else if (Action==="Toggle_UCL"){
          let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
          _ModuleCfg.bUCL = !_ModuleCfg?.bUCL
          /** set UCL_ListAlso */
          if(_ModuleCfg.bUCL===true)  _ModuleCfg.UCL_List = [GC_Init_xCL]
          else {
            _ModuleCfg.UCL_List = []        
            delete _ModuleCfg.UCL_Value;
          }
          setG_ModuleCfg(_ModuleCfg)
        }else if (Action==="Toggle_LCL"){
          let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
          _ModuleCfg.bLCL = !_ModuleCfg?.bLCL
          /** set LCL_ListAlso */
          if(_ModuleCfg.bLCL===true)  _ModuleCfg.LCL_List = [GC_Init_xCL]
          else {
            _ModuleCfg.LCL_List = []        
            delete _ModuleCfg.LCL_Value;
          }
          setG_ModuleCfg(_ModuleCfg)
        }else if (Action==="Y_HiLimit"){
          let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
          _ModuleCfg.bY_Hi = !_ModuleCfg?.bY_Hi      
          if (!_ModuleCfg.bY_Hi){
            delete _ModuleCfg.Y_HiLimit_Value;
          }
          setG_ModuleCfg(_ModuleCfg)
        }else if (Action==="Y_LoLimit"){
          let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
          _ModuleCfg.bY_Lo = !_ModuleCfg?.bY_Lo      
          if (!_ModuleCfg.bY_Lo){
            delete _ModuleCfg.Y_LoLimit_Value;
          }
          setG_ModuleCfg(_ModuleCfg)
        }
    };

    const F_SetValue=(event, xCL)=>{
        const sValue = event.target.value;
        let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
    
        if (xCL==="UCL"){
          _ModuleCfg.UCL_Value = parseFloat(sValue)
        }else if (xCL==="LCL"){
          _ModuleCfg.LCL_Value = parseFloat(sValue)
        }else if (xCL==="CL"){
          _ModuleCfg.CL_Value = parseFloat(sValue)
        }else if (xCL==="Y_HiLimit"){
          _ModuleCfg.Y_HiLimit_Value = parseFloat(sValue)
        }else if (xCL==="Y_LoLimit"){
          _ModuleCfg.Y_LoLimit_Value = parseFloat(sValue)
        }
    
        setG_ModuleCfg(_ModuleCfg)
    }

    const F_ToggleCuz_xCL = (xCL, index) => {
        let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
        if (xCL==="UCL"){
          _ModuleCfg.UCL_List[index].selected = !_ModuleCfg.UCL_List[index].selected
          /** add more  */
          if(_ModuleCfg.UCL_List[index].selected){    /** selected, add 1 more line */
            _ModuleCfg.UCL_List.push(GC_Init_xCL)
          }else{  /** deselect, delete 1 line */
            _ModuleCfg.UCL_List.splice(index,1)
          }
        } else if (xCL==="CL"){
          _ModuleCfg.CL_List[index].selected = !_ModuleCfg.CL_List[index].selected
          /** add more  */
          if(_ModuleCfg.CL_List[index].selected){    /** selected, add 1 more line */
            _ModuleCfg.CL_List.push(GC_Init_xCL)
          }else{  /** deselect, delete 1 line */
            _ModuleCfg.CL_List.splice(index,1)
          }
        } else if (xCL==="LCL"){
          _ModuleCfg.LCL_List[index].selected = !_ModuleCfg.LCL_List[index].selected
          /** add more  */
          if(_ModuleCfg.LCL_List[index].selected){    /** selected, add 1 more line */
            _ModuleCfg.LCL_List.push(GC_Init_xCL)
          }else{  /** deselect, delete 1 line */
            _ModuleCfg.LCL_List.splice(index,1)
          }
        } else if (xCL==="HighLight"){
          _ModuleCfg.HighLight_List[index].selected = !_ModuleCfg.HighLight_List[index].selected
          /** add more  */
          if(_ModuleCfg.HighLight_List[index].selected){    /** selected, add 1 more line */
            _ModuleCfg.HighLight_List.push(GC_Init_xCL)
          }else{  /** deselect, delete 1 line */
            _ModuleCfg.HighLight_List.splice(index,1)
          }
    
        }
    
        setG_ModuleCfg(_ModuleCfg)
    }

    // eslint-disable-next-line
    const F_ToggleRepeatDaily_xCL =  (xCL, index) => {
        let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
        if (xCL==="UCL"){
          _ModuleCfg.UCL_List[index].repeatDaily = !_ModuleCfg.UCL_List[index].repeatDaily
        } else if (xCL==="CL"){
          _ModuleCfg.CL_List[index].repeatDaily = !_ModuleCfg.CL_List[index].repeatDaily
        } else if (xCL==="LCL"){
          _ModuleCfg.LCL_List[index].repeatDaily = !_ModuleCfg.LCL_List[index].repeatDaily
        } else if (xCL==="HighLight"){
          _ModuleCfg.HighLight_List[index].repeatDaily = !_ModuleCfg.HighLight_List[index].repeatDaily
        }
        setG_ModuleCfg(_ModuleCfg);
    }

    const F_SetStart=(event, idx, paraType, xCL)=>{    
        const sValue = event.target.value;
        let _ModuleCfg = JSON.parse(JSON.stringify(G_ModuleCfg));
    
        if(paraType==="Start"){
          if (xCL==="CL") _ModuleCfg.CL_List[idx].startTime = sValue;
          else if (xCL==="UCL") _ModuleCfg.UCL_List[idx].startTime = sValue;
          else if (xCL==="LCL") _ModuleCfg.LCL_List[idx].startTime = sValue;
          else if (xCL==="HighLight") _ModuleCfg.HighLight_List[idx].startTime = sValue;
        }else if (paraType==="End"){
          if (xCL==="CL") _ModuleCfg.CL_List[idx].endTime = sValue;
          else if (xCL==="UCL") _ModuleCfg.UCL_List[idx].endTime = sValue;
          else if (xCL==="LCL") _ModuleCfg.LCL_List[idx].endTime = sValue;
          else if (xCL==="HighLight") _ModuleCfg.HighLight_List[idx].endTime = sValue;
        }else if (paraType==="value"){
          if (xCL==="CL") _ModuleCfg.CL_List[idx].value = parseFloat(sValue);
          if (xCL==="UCL") _ModuleCfg.UCL_List[idx].value = parseFloat(sValue);
          if (xCL==="LCL") _ModuleCfg.LCL_List[idx].value = parseFloat(sValue);
          // if (xCL==="HighLight") _ModuleCfg.HighLight_List[idx].value = parseFloat(sValue);
        }
    
        setG_ModuleCfg(_ModuleCfg)
      }

    const F_setColor = (newColor) => {
        const updatedColors = [...G_colorPicked];
        updatedColors[G_cpIdx] = newColor;
        setG_colorPicked(updatedColors);
        F_SetDiaPage(0);  /** Close the selection dialog */
    }

    const F_addColorPicker = () => {
        // let currColor = "#ffffff";
        // if(G_colorPicked.length > 10) currColor = defaultLineColor[G_colorPicked.length];
        setG_colorPicked([...G_colorPicked, defaultLineColor[G_colorPicked.length % 10]]); // Add an empty string or null for the new picker
    };

    // const F_SlotInLineColor=()=>{
    //     let _GraphParaList = JSON.parse(JSON.stringify(G_GraphParaList));            
    //         for (let i = 0; i < G_colorPicked.length; i++) {             
    //             if(_GraphParaList.length > i){           
    //                 _GraphParaList[i].colorCode = G_colorPicked[i]
    //             }                
    //         }
    //     return _GraphParaList
    // }

    const F_SendToAnalysis=async()=>{
        if(!G_sendDisabled) {

            setG_sendDisabled(true);     // resume this line later
    
            // F_AllTimeZone()
            
            // if (!F_ValidateConfig()) return 
            
            let moduleInfo = {
              name:G_sModuleName,
              user_id: F_GetUserId(),
              startDate: G_sStartDate,
              endDate:G_sEndDate,
              timezone:G_sTimezone,
              PivotInterval:G_sPivotInterval,
              StatisticMeasure:G_sStatMeasure,
              bSupportChinese:G_bMultiLanguage,
            //   bGroupByWeek:G_GroupByWeek,
              AuxConfig:G_ModuleCfg,
              graphParas: G_GraphParaList,
              lineColors: G_colorPicked,
            //   LineDisplayOption:F_GetLineDisplayOption(G_LineDisplayOption)
            //   comparePara,
                
            }
        
            // return console.log(moduleInfo);
            
            let rel = await daReqFn.F_InsertDaReq_R02(G_ModuleID, moduleInfo);
            
            
            if (rel.errMsg) {
                setG_sendDisabled(false);
                return toast(rel.errMsg)
            }
            
            toast("Success")
            F_ToPage(0)
        }
    }

    return (
        <div className="Summer_EachPg ">
            <TpPgHeader
                headerTitle={"Line Trend Module"}
                onClick_Back={() => F_ToPage(0)}
            />

            {G_nSelectionPg === 1 && <TpChooseSensorWithBd
                onClickCancel={()=>F_SetDiaPage(0)} 
                onClickSelItem={F_AddPara}
            />}

            {G_nSelectionPg===3 && <TpSelection 
                titleName={'Time Zone'}
                onClickCancel={()=>F_SetDiaPage(0)} 
                disName = {'name'}
                onClickSelItem = {F_ChooseTz}        
                selList={F_AllTimeZone()}
            />}

            {G_nSelectionPg === 4 && <TpColorPicker
                cpIdx = {G_cpIdx}
                onClickCancel={()=>F_SetDiaPage(0)} 
                colorPicked = {G_colorPicked[G_cpIdx]}
                onClickSetColor={F_setColor}
                // onClickSelItem={F_AddPara}
            />} 

            <div className="Summer_PageLayout">
                <div className="Summer_ModuleContainer">
                    <div className="Summer_ModuleSubItemContainer">
                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Name :</div>
                            <div className="Summer_Info_Value">
                                <input
                                id="in_do_name"
                                className="Summer_info_input"
                                placeholder="<Insert Name>"
                                onChange={(e)=>F_SetName_Date(e, 1)}
                                />
                            </div>
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Start:</div>
                            <div>
                                <input
                                id="in_do_start"
                                className="Summer_TimeInput"
                                type="datetime-local"
                                onChange={(e)=>F_SetName_Date(e, 2)}
                                />
                            </div>
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">End:</div>
                            <div className="">
                                <input
                                id="in_do_end"
                                className="Summer_TimeInput"
                                type="datetime-local"
                                onChange={(e)=>F_SetName_Date(e, 3)}
                                />
                            </div>
                        </div>
                        
                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Timezone:</div>
                            <div className="hoverPointer" onClick={()=>F_SetDiaPage(3)}>
                                {G_sTimezone===""?
                                <div className="Summer_PlaceHolderColor">{`<Choose Time Zone>`}</div>:
                                <div className="Summer_info_input">{G_sTimezone}</div>}
                            </div>
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Pivot interval:</div>
                            <div style={{ marginRight: "2px" }} className="hoverPointer" onClick={F_RotInterval}>
                                <div className="Summer_SubcontainerSelButton">{G_sPivotInterval}</div>
                            </div>
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Statistical measure:</div>
                            <div style={{ marginRight: "2px" }} className="hoverPointer" onClick={F_RotStatMeasure}>
                                <div className="Summer_SubcontainerSelButton">{G_sStatMeasure}</div>
                            </div>
                        </div>
                        
                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Display non-alphabet characters:</div>
                            <div style={{ marginRight: "2px" }} className="hoverPointer">
                                <TpToggleButton
                                    status={G_bMultiLanguage}
                                    onClick_in={() => F_Toggle("MultiLanguage")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="Summer_ModuleSubItemContainer">            
                        <div className="Summer_SubcontainerTitle">
                            Graph Parameter
                        </div>

                        {G_GraphParaList.map((item, ind) => (
                            <div key={`key_chiDpm_${ind}`} className="Summer_DaReq_DevList sortHor">
                                <div>{`${ind+1}.`}</div>
                                <div className="Summer_DaReq_Item_Name sortHor " style={{minWidth:"10px"}}>
                                    <div className="wrapTextEllipsis" >{`${item?.bdInfo?.name}`}</div>
                                    <div className="Summer_DaReq_LevelSeperator wrapTextEllipsis">{">"}</div>
                                    <div className="wrapTextEllipsis" >{`${item?.sensorInfo?.name}`}</div>
                                    <div className="Summer_DaReq_LevelSeperator wrapTextEllipsis">{">"}</div>
                                    <div className="wrapTextEllipsis" >{`${item?.paraInfo?.dataName}`}</div>
                                </div>            
                                <div className="stayRight sortHor"> 
                                    <div className="Summer_DaReq_Item_ActIcon hoverPointer Summer_TpSel_CheckBox bothCenter"
                                    style={{ backgroundColor: G_colorPicked[ind]}}
                                    onClick={()=>F_SetDiaPage(4, ind)}
                                    ></div>
                                    <div className="Summer_DaReq_Item_ActIcon hoverPointer blueColor"
                                    onClick={()=>F_ShiftUp_FinalList(ind)}
                                    ><IoArrowUp/></div>
                                    <div className="Summer_DaReq_Item_ActIcon hoverPointer yellowColor"
                                    onClick={()=>F_ShiftDn_FinalList(ind)}
                                    ><IoArrowDown/></div>
                                    <div className="Summer_DaReq_Item_ActIcon hoverPointer wineColor"
                                    onClick={()=>F_ShiftDel_FinalList(ind)}
                                    ><RiDeleteBinLine/></div>                
                                </div>  
                            </div>
                        ))}
                        <div className="Summer_DaReq_AddButton hoverPointer" onClick={()=>F_SetDiaPage(1)}>Add +</div>           

                        {/* <div className="sortHor Summer_ModuleCfg_Item hoverPointer"
                        onClick={()=>F_SetDiaPage(1)}>
                        Choose Sensor
                        </div> */}
                    </div>

                    
                    {G_GraphParaList.length > 1 && <div className="Summer_ModuleSubItemContainer">
                        <div className="Summer_SubcontainerTitle">
                            Line Options
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Display Option:</div>
                            <div style={{ marginRight: "2px"}} className="hoverPointer" 
                            onClick={F_RotLineOption}
                            >
                                <div className="Summer_SubcontainerSelButton" style={{width:"100px"}}>{F_GetLineDisplayOption(G_ModuleCfg?.GraphOption?.LineDisplayOption)}</div>
                            </div>
                        </div>

                        {G_ModuleCfg?.GraphOption?.LineDisplayOption === "Sum Up" && <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Sum Up Display Name :</div>
                            <div className="Summer_Info_Value">
                                <input
                                id="in_sumup_name"
                                className="Summer_info_input"
                                placeholder="<Insert Name>"
                                // value={G_yAxisName}
                                onChange={(e)=>F_SetName_Date(e, 6)}
                                />
                            </div>
                        </div>}

                    </div>}

                    <div className="Summer_ModuleSubItemContainer">
                        <div className="Summer_SubcontainerTitle">
                            Graph Options
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Graph title:</div>
                            <div className="Summer_Info_Value">
                                <input
                                id="in_graph_name"
                                className="Summer_info_input"
                                placeholder="<Insert Name>"
                                onChange={(e)=>F_SetName_Date(e, 5)}
                                />
                            </div>
                        </div>

                        <div className="sortHor Summer_InfoKeyInSlot">
                            <div className="Summer_Info_Title">Y-Axis label:</div>
                            <div className="Summer_Info_Value">
                                <input
                                id="in_yaxis_name"
                                className="Summer_info_input"
                                placeholder="<Insert Name>"
                                // value={G_yAxisName}
                                onChange={(e)=>F_SetName_Date(e, 4)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="Summer_ModuleSubItemContainer">            
                        <div className="Summer_SubcontainerTitle">
                        Control Limit
                        </div>         

                        <div className="sortHor Summer_ModuleCfg_Item">
                        <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                            onClick={() => F_Toggle("Toggle_UCL")}
                        >
                            {G_ModuleCfg?.bUCL ? <FaCheck /> : ""}
                        </div>
                        <div>UCL - Upper Control Limit</div>
                        {G_ModuleCfg?.bUCL ? (
                            <input type="number" id="in_do_ucl" className="stayRight Summer_info_input" placeholder="<Insert value>"
                            onChange={(e) => F_SetValue(e, "UCL")}
                            />) : (<div></div>)}
                        </div>

                        {G_ModuleCfg?.bUCL===true && Array.isArray(G_ModuleCfg?.UCL_List) && G_ModuleCfg?.UCL_List.map((item, ind) => (<div key={`UCL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
                            <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" 
                            onClick={()=>F_ToggleCuz_xCL("UCL", ind)}
                            >{item?.selected?<FaCheck/>:<div/>}</div>
                                <div>{`Customize UCL ${ind+1}`}</div>
                                {item?.selected?<div className="stayRight">
                                <TpDatetimeRangeAndValue config={item} className=""
                                listIdx = {ind}
                                onChange_SetStart={F_SetStart}
                                xCL = "UCL"
                                unique={`UCL_${ind}`}
                                />
                                </div>:<div/>}
                        </div>))}

                        <div className="sortHor Summer_ModuleCfg_Item">
                        <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                            onClick={() => F_Toggle("Toggle_CL")}
                        >
                            {G_ModuleCfg?.bCL ? <FaCheck /> : ""}
                        </div>
                        <div>CL - Control Limit</div>
                        {G_ModuleCfg?.bCL ? (
                            <input type="number" id="in_do_cl" className="stayRight Summer_info_input" placeholder="<Insert value>"
                            onChange={(e) => F_SetValue(e, "CL")}
                            />) : (<div></div>)}
                        </div>

                        {G_ModuleCfg?.bCL===true && Array.isArray(G_ModuleCfg?.CL_List) && G_ModuleCfg?.CL_List.map((item, ind) => (<div key={`CL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
                            <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" 
                            onClick={()=>F_ToggleCuz_xCL("CL", ind)}
                            >{item?.selected?<FaCheck/>:<div/>}</div>
                            <div>{`Customize CL ${ind+1}`}</div>
                            {item?.selected?<div className="stayRight">
                            <TpDatetimeRangeAndValue config={item} className=""
                            listIdx = {ind}
                            onChange_SetStart={F_SetStart}
                            xCL = "CL"
                            unique={`CL_${ind}`}
                            />
                            </div>:<div/>}
                        </div>))}



                        

                        <div className="sortHor Summer_ModuleCfg_Item">
                        <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                            onClick={() => F_Toggle("Toggle_LCL")}
                        >
                            {G_ModuleCfg?.bLCL ? <FaCheck /> : ""}
                        </div>
                        <div>LCL - Lower Control Limit</div>
                        {G_ModuleCfg?.bLCL ? (
                            <input type="number" id="in_do_lcl" className="stayRight Summer_info_input" placeholder="<Insert value>"
                            onChange={(e) => F_SetValue(e, "LCL")}
                            />) : (<div></div>)}
                        </div>

                        
                        {G_ModuleCfg?.bLCL===true && Array.isArray(G_ModuleCfg?.LCL_List) && G_ModuleCfg?.LCL_List.map((item, ind) => (<div key={`LCL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
                            <div className="Summer_ChildItemPrefix bothCenter"><TbPointFilled/></div>
                            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer" 
                            onClick={()=>F_ToggleCuz_xCL("LCL", ind)}
                            >{item?.selected?<FaCheck/>:<div/>}</div>
                            <div>{`Customize LCL ${ind+1}`}</div>
                            {item?.selected?<div className="stayRight">
                            <TpDatetimeRangeAndValue config={item} className=""
                                listIdx = {ind}
                                onChange_SetStart={F_SetStart}
                                xCL = "LCL"
                                unique={`LCL_${ind}`}
                            />
                            </div>:<div/>}
                        </div>))}

                    </div>

                    <div className="Summer_ModuleSubItemContainer">
                        <div className="Summer_SubcontainerTitle">
                        Highlight
                        </div>
                        
                        {Array.isArray(G_ModuleCfg?.HighLight_List) && G_ModuleCfg?.HighLight_List.map((item, ind) => (<div key={`LCL_${ind}`} style={{height:"25px"}} className="sortHor Summer_ModuleCfg_Item">
                            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                                onClick={()=>F_ToggleCuz_xCL("HighLight", ind)}
                            >                
                                {item?.selected? <FaCheck /> : ""}
                            </div>
                            <div>{`Highlight ${ind+1}`}</div>
                            {item?.selected?<div className="stayRight">
                                <TpDatetimeRangeAndValue 
                                config={item} className=""
                                listIdx = {ind}
                                onChange_SetStart={F_SetStart}
                                xCL = "HighLight"
                                unique={`HighLight_${ind}`}
                                bHideValue  = {true}
                                />
                            </div>:<div/>}
                        </div>))}
                    </div>

                    <div className="Summer_ModuleSubItemContainer">
                        <div className="Summer_SubcontainerTitle">
                            Scale limit
                        </div>

                        <div className="sortHor Summer_ModuleCfg_Item">
                            <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                                onClick={() => F_Toggle("Y_HiLimit")}
                            >
                                {G_ModuleCfg?.bY_Hi ? <FaCheck /> : ""}
                            </div>
                            <div>Y Axis, Hi Limit</div>
                            {G_ModuleCfg?.bY_Hi ? (
                                <input type="number" className="stayRight Summer_info_input" placeholder="<Insert value>"
                                onChange={(e) => F_SetValue(e, "Y_HiLimit")}
                                />) : (<div></div>)}
                            </div>
                            
                            <div className="sortHor Summer_ModuleCfg_Item">
                                <div className="Summer_TpSel_CheckBox bothCenter hoverPointer"
                                    onClick={() => F_Toggle("Y_LoLimit")}
                                >
                                    {G_ModuleCfg?.bY_Lo ? <FaCheck /> : ""}
                                </div>
                                <div>Y Axis, Lo Limit</div>
                                {G_ModuleCfg?.bY_Lo ? (
                                    <input type="number" className="stayRight Summer_info_input" placeholder="<Insert value>"
                                    onChange={(e) => F_SetValue(e, "Y_LoLimit")}
                            />) : (<div></div>)}
                        </div>
                    </div>

                    <div className="Summer_DaReq_SendButton hoverPointer" 
                        onClick={F_SendToAnalysis}
                        style={{ cursor: G_sendDisabled? 'not-allowed' : 'pointer', opacity: G_sendDisabled? 0.5 : 1, }}
                    >
                        Send
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Module_0003_LineTrend;