import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { HexColorPicker } from 'react-colorful';

function TpColorPicker(props) {
    const [G_localColor, setG_localColor] = useState(props.colorPicked); // Local state for color
    
    const swatches = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

    const F_HandleCancel = () => {
        if (props.onClickCancel) props.onClickCancel();
    };

    const F_Comfirm = () => {
        if(props.onClickSetColor) props.onClickSetColor(G_localColor);
    }

    return (
        <div className="Summer_DialogPg">
            <div className="Summer_GreyoutPage"></div>

            <div className="Summer_DialogBox lowerZ_idx Summer_Shadow Summer_HidFrameContainer">
                <div className="Summer_DiaCancel" onClick={F_HandleCancel}>
                    <IoClose className="Summer_DiaCancelPos" />
                </div>
                <div className="Summer_DiaTitle">Graph Color Picker</div>

                <div className={`Summer_DiaItemContainer color-picker-container`} style={{ height: "400px"}}>
                    <HexColorPicker color={G_localColor} onChange={setG_localColor} />
                    <div className='sortHor spreadAround Summer_DiaSearchContainer'>
                        <div className='Summer_roundButton' style={{ backgroundColor: G_localColor }}></div>
                        <div className='Summer_roundContainer_withBorder'>{G_localColor}</div>
                    </div>
                    <div className="swatches-container">
                        {swatches.map((swatchColor, idx) => (
                            <div
                                key={idx}
                                className={`swatch ${swatchColor === G_localColor ? 'selected' : ''}`}
                                style={{ backgroundColor: swatchColor }}
                                onClick={() => setG_localColor(swatchColor)}
                            />
                        ))}
                    </div>

                    <div className="Summer_DaReq_SendButton hoverPointer" style={{marginTop:"20px"}}
                        onClick={F_Comfirm}
                    >
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TpColorPicker;